import { CurrencyService } from '@/services/currency.service';
import { IConversionResponse, ICurrencyConversion } from '@/services/currencyCoversion';
import { StartupService } from '@/startup.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CarrierResponse, CreateCarrierInvoice, EditCarrierInvoice } from 'src/app/models/Carrier';
import { CarrierService } from 'src/app/services/carrier.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AuthService } from '../../auth/auth.service';
import { AuditCarrierUpdateListComponent } from '@/bg-common/audit-carrier-update-list/audit-carrier-update-list.component';
import { EditInvoiceParameters } from '../../models/edit-invoice-parameters';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { Globals } from '../../_shared/globals';
import { Helpers } from '../../_shared/helpers';
import { AuditCarrierInvoiceUI } from '@/pages/audit/AuditCarrierInvoice.ui';
import ui = AuditCarrierInvoiceUI;
import { ShipmentDTO } from '@/services/Shipment';

@Component({
  selector: 'app-audit-carrier-invoice',
  templateUrl: './audit-carrier-invoice.component.html',
  styleUrls: ['./audit-carrier-invoice.component.scss'],
})
export class AuditCarrierInvoiceComponent implements OnInit {
  @ViewChild('auditCarrierGrid', { static: false })
  private chargesGrid: AuditCarrierUpdateListComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild('tldropdownlist', { static: false })
  tlDropDownList: any;

  @Input()
  parameters: EditInvoiceParameters;

  @Output()
  onInvoiceCreated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onInvoiceUpdated: EventEmitter<any> = new EventEmitter<any>();

  get controls() {
    return this.carrierForm.controls;
  }

  isDataloading = true;
  isLoadingCarriers = false;
  paymentTypes = Globals.PaymentTypes;
  invoiceCurrencyList = Globals.invoiceCurrencyList;
  carrierInvoiceAudit: ui.CarrierInvoiceAudit = null;
  carrierForm: FormGroup = null;
  carrierNameError = '';
  carrierSearchValue = '';
  shipmentDTO: ShipmentDTO;

  helpers: Helpers = null;
  shipmentID: number;
  invoiceID: number;
  loadingMessage: string;
  editMode: boolean;
  initialLoad: boolean;
  isDotSearch: boolean;
  quoteTotal = 0;
  submittingForm = false;
  pageTitle: string;
  carrierList: CarrierResponse[] = null;
  carrierExactMatch: CarrierResponse[] = null;
  controlCarrierList: CarrierResponse[] = null;
  chargesInEditProgress = false;
  loadNormalizeTotal: number = 0;
  selectInvoice: string;
  normalizedCode: string;
  loadSpinnerNormalize: boolean = false;
  customerNormalizedTotal: number = 0;
  normalizedTotalCurrency: number = 0;
  carrierTotal: number;
  infoCurrencyResponse: IConversionResponse;
  today: Date = new Date();
  todayMaxValidator: Date;
  constructor(
    private startupService: StartupService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private pcFinanceService: CarrierService,
    private pcShipmentService: ShipmentService,
    private currencyService: CurrencyService
  ) {
    this.helpers = new Helpers(this.authService);
  }

  ngOnInit() {
    this.shipmentID = this.parameters.shipmentID;
    this.invoiceID = this.parameters.invoiceID;
    this.editMode = this.parameters.isEditMode;

    this.loadingMessage = 'Loading invoice...';
    this.isDotSearch = false;
    this.initialLoad = true;
    this.loadPage();
  }

  // events
  public searchInputKeyUp(e: KeyboardEvent): void {
    const value = this.carrierForm.get('carrierName').value;
    if (e.key === 'Enter' && value.length >= 3) {
      e.preventDefault();
      this.onCarrierNameFilter(value);
    }
  }

  public get payloadConvertedCurrency(): ICurrencyConversion {
    const currencyDate = new Date(this.carrierForm.get('invoiceDate').value);
    return {
      EffectiveDate: `${currencyDate.getFullYear()}-${this.addZero(currencyDate.getMonth() + 1)}-${this.addZero(currencyDate.getDate())}`,
      Amount: this.carrierTotal,
      CurrencyCode: this.selectInvoice,
      CountryCode: this.selectInvoice === 'CAD' ? 'CAN' : 'USA',
      DestinationCurrencyCode: 'USD',
      DestinationCountryCode: 'USA',
    };
  }

  private addZero(number: number): string {
    return (number < 10) ? `0${number}` : number.toString();
  }

  protected nameSearch() {
    this.isDotSearch = false;
    this.controlCarrierList = [];
    this.carrierForm.get('carrierName').setValue('');
  }
  protected dotSearch() {
    this.isDotSearch = true;
    this.controlCarrierList = [];
    this.carrierForm.get('carrierName').setValue('');
  }

  public submitForm() {
    // the total cannot be negative
    if (this.chargesGrid.getTotal() < 0) {
      this.alertMessage.showAlertMessage('The Invoice cannot be negative', 'Error');
      return;
    }
    this.submittingForm = true;
    // map to correct model
    this.showSpinner(true, 'Saving invoice...');
    let theRecord;

    if (this.editMode) theRecord = this.updateCarrierInvoice();
    else {
      theRecord = this.createCarrierInvoice();
    }

    if (this.parameters.enterprise.currencyCode !== 'USD') {
      theRecord.normalizedTotal = this.infoCurrencyResponse.amount;
      theRecord.normalizedCurrencyCode = this.infoCurrencyResponse.currencyCode;
    }

    if (this.editMode) this.editCarrierInvoice(theRecord);
    else this.addCarrierInvoice(theRecord);
  }

  public editCarrierInvoice(theRecord: EditCarrierInvoice): void {
    this.pcFinanceService.updateCarrierInvoice(this.invoiceID, theRecord).subscribe(
      (data) => {
        this.showSpinner(false);
        this.onInvoiceUpdated.emit(data);
      },
      (error) => {
        console.log(error);
        this.submittingForm = false;
        this.showSpinner(false);
        this.alertMessage.showAlertMessage('Error updating the Carrier invoice', 'Error');
      }
    );
  }

  public addCarrierInvoice(theRecord: CreateCarrierInvoice): void {
    this.pcFinanceService.createCarrierInvoice(theRecord).subscribe(
      (data) => {
        this.showSpinner(false);
        this.onInvoiceCreated.emit(data);
      },
      (error) => {
        console.log(error);
        this.submittingForm = false;
        this.showSpinner(false);
        this.alertMessage.showAlertMessage('Error creating the Carrier invoice', 'Error');
      }
    );
  }

  public onTotalChanged(total: number) {
    total = +total.toFixed(2);

    if (total !== this.carrierInvoiceAudit.invoiceTotal) {
      this.carrierInvoiceAudit.invoiceTotal = total;
      this.carrierTotal = total;
      this.customerNormalizedTotal = total;
    }
    this.selectInvocieCurrency(this.selectInvoice);
  }
  public onEditInProgress(isEditing: boolean) {
    this.chargesInEditProgress = isEditing;
  }

  public distanceBlur() {
    this.carrierInvoiceAudit.distance = this.carrierForm.get('distance').value;
    this.chargesGrid.updateDistanceCharges(this.carrierForm.get('distance').value);
  }

  onCarrierNamevalueChange(value) {
    // did we find it?
    this.carrierNameError = '';
    const carrier = this.carrierList.find((i) => i.refCarrierID === value);
    if (!carrier) {
      this.carrierInvoiceAudit.scac = 'n/a';
      this.carrierInvoiceAudit.mcNumber = 'n/a';
      this.carrierInvoiceAudit.dotNumber = 'n/a';
      this.carrierInvoiceAudit.carrierCode = 'n/a';
      return;
    }

    // set the values
    this.setCarrier(carrier);
    this.helpers.updateAllValidity(this.carrierForm);
  }

  private filterCarriers = (value: string) => {
    value = (value || "").trim().toLocaleLowerCase();
    this.carrierExactMatch = this.carrierList.filter((s) => s.carrierName.toLocaleLowerCase() === value);
    this.controlCarrierList = this.carrierExactMatch.length > 0 ? this.carrierExactMatch : this.carrierList.filter((s) => s.carrierName.toLocaleLowerCase().startsWith(value));
  };

  private searchCarriers = (value: string) => {
    value = (value || '').trim().toLocaleLowerCase();
    this.controlCarrierList = this.carrierList.filter((s) => s.carrierName.toLocaleLowerCase().startsWith(value));
  };

  onCarrierNameFilter(value: string) {
    value = (value || "").trim().toLocaleLowerCase();
    const lastSearch = (this.carrierSearchValue || "").toLocaleLowerCase();

    if (value.length >= 3 && !(lastSearch && value.startsWith(lastSearch))) {
      this.loadLTLCarriers(value, null);
      this.carrierSearchValue = value;
    } else {
      this.searchCarriers(value);
    }
  }

  public carrierNameValidator(control: FormControl) {
    if (!this.isLTL()) {
      if (this.carrierForm) {
        // no list so defintily not found
        if (this.controlCarrierList.length === 0) {
          return { invalidCarrier: true };
        }

        // find it in the list?
        const value = this.carrierForm.get('carrierName').value;
        const index = this.controlCarrierList.findIndex((i) => i.refCarrierID === value);
        if (index === -1) {
          return { invalidCarrier: true };
        }

        // only need to force the section on the first page load
        if (this.initialLoad) {
          this.setCarrier(this.controlCarrierList[index]);
          this.initialLoad = false;
        }
      }
    }
    return null;
  }

  public selectInvocieCurrency(event?): void {
    this.loadSpinnerNormalize = true;
    this.selectInvoice = event || this.carrierForm.get('invoiceCurrency').value;
    this.carrierInvoiceAudit.currencyCode = this.selectInvoice;
    if (this.parameters.enterprise.currencyCode !== 'USD') {
      this.currencyService.currencyConversion(this.payloadConvertedCurrency).subscribe((info: IConversionResponse) => {
        this.normalizedTotalCurrency = info.amount
        this.infoCurrencyResponse = info;
        this.loadSpinnerNormalize = false;
      }, () => {
        this.alertMessage.showAlertMessage('Error currency conversion failed, Invoice Date is in the future', 'Error');
        this.loadSpinnerNormalize = false;
      });
    }
  }


  // form functions
  private createForm() {
    let refCarrierID = 0;

    // if we didnt find it in the list we need to clear it out.
    if (this.isLTL() && (this.carrierInvoiceAudit.carrierName != null &&
      (this.controlCarrierList == null ||
        this.controlCarrierList.length === 0 ||
        this.carrierInvoiceAudit.carrierName.toUpperCase() !== this.controlCarrierList[0].carrierName.toUpperCase()))) {
      if (this.carrierInvoiceAudit.carrierName !== '') {
        this.carrierNameError = `Carrier '${this.carrierInvoiceAudit.carrierName}' not found.`;
        this.carrierInvoiceAudit.carrierName = '';
      }
    }

    if (this.controlCarrierList !== null && this.controlCarrierList.length > 0) {
      if (!this.isLTL()) {
        // TL compare DOT
        const findmatch = this.controlCarrierList.filter(
          (c) =>
            c.carrierName.toUpperCase() === this.carrierInvoiceAudit.carrierName.toUpperCase() &&
            c.dotNumber === this.carrierInvoiceAudit.dotNumber
        );
        if (findmatch.length === 1) {
          refCarrierID = findmatch[0].refCarrierID;
        }
      } else {
        // LTL compare SCAC
        const findmatch = this.controlCarrierList.filter(
          (c) =>
            c.carrierName.toUpperCase() === this.carrierInvoiceAudit.carrierName.toUpperCase() && c.scac === this.carrierInvoiceAudit.scac
        );
        if (findmatch.length === 1) {
          refCarrierID = findmatch[0].refCarrierID;
        }
      }
    }

    this.carrierForm = this.formBuilder.group({
      carrierName: (!this.isLTL()) ?
        [this.carrierInvoiceAudit.carrierName] :
        [
          refCarrierID !== 0 ? refCarrierID : '',
          [Validators.required, this.carrierNameValidator.bind(this)]
        ],
      invoiceNumber: [this.carrierInvoiceAudit.invoiceNumber, [Validators.required]],
      invoiceDate: [this.carrierInvoiceAudit.invoiceDate, [Validators.required]],
      paymentMethod: [this.carrierInvoiceAudit.paymentMethod, [Validators.required]],
      weight: [this.carrierInvoiceAudit.weight, [Validators.required, Validators.min(1)]],
      distance: [this.carrierInvoiceAudit.distance, [Validators.required, Validators.min(0)]],
      invoiceCurrency: [this.carrierInvoiceAudit.currencyCode, [Validators.required]],
    });

    // validate the form
    this.helpers.markAllAsTouchedDirty(this.carrierForm);
    this.helpers.updateAllValidity(this.carrierForm);
  }

  // helpers
  private setCarrier(carrier: CarrierResponse) {
    this.carrierInvoiceAudit.scac = carrier.scac;
    this.carrierInvoiceAudit.mcNumber = carrier.mcNumber;
    this.carrierInvoiceAudit.dotNumber = carrier.dotNumber;
    this.carrierInvoiceAudit.carrierCode = carrier.carrierCode;
    this.carrierInvoiceAudit.carrierName = carrier.carrierName;
  }

  private updateCarrierInvoice(): EditCarrierInvoice {
    const currencyDate = new Date(this.carrierForm.get('invoiceDate').value);
    const theRecord = <EditCarrierInvoice>{
      currency: 'USD',

      // sent in
      carrierCode:
        this.carrierInvoiceAudit.carrierCode === undefined || this.carrierInvoiceAudit.carrierCode === 'n/a'
          ? ''
          : this.carrierInvoiceAudit.carrierCode,
      mcNumber:
        this.carrierInvoiceAudit.mcNumber === undefined || this.carrierInvoiceAudit.mcNumber === 'n/a'
          ? ''
          : this.carrierInvoiceAudit.mcNumber,
      dotNumber:
        this.carrierInvoiceAudit.dotNumber === undefined || this.carrierInvoiceAudit.dotNumber === 'n/a'
          ? ''
          : this.carrierInvoiceAudit.dotNumber,
      scac: this.carrierInvoiceAudit.scac === undefined || this.carrierInvoiceAudit.scac === 'n/a' ? '' : this.carrierInvoiceAudit.scac,

      // form
      carrierName: this.carrierInvoiceAudit.carrierName,
      invoiceDate: new Date(`${currencyDate.getFullYear()}-${this.addZero(currencyDate.getMonth() + 1)}-${this.addZero(currencyDate.getDate())}`),
      invoiceNumber: this.carrierForm.get('invoiceNumber').value,
      invoiceWeight: this.carrierForm.get('weight').value,
      distance: this.carrierForm.get('distance').value,
      serviceTotal: this.chargesGrid.getServiceTotal(),
      freightTotal: this.chargesGrid.getFreightTotal(),
      paymentTerms: this.carrierForm.get('paymentMethod').value,
      currencyCode: this.carrierForm.get('invoiceCurrency').value,
    };

    // add the charges
    const charges = this.chargesGrid.getCharges();
    let sequence = 1;
    theRecord.charges = charges.map(
      ({
        invoiceChargeID,
        type,
        group,
        description,
        ediCode,
        amount,
        rate,
        rateCode: rateQualifier,
        quantity,
        weight,
        dimWeight,
        freightClass,
        fakFreightClass,
      }) => ({
        id: invoiceChargeID,
        sequence: sequence++,
        type,
        group,
        description,
        ediCode,
        amount,
        rate,
        rateQualifier,
        quantity,
        weight,
        dimWeight,
        freightClass,
        fakFreight: fakFreightClass,
      })
    );

    return theRecord;
  }
  private createCarrierInvoice(): CreateCarrierInvoice {
    const currencyDate = new Date(this.carrierForm.get('invoiceDate').value);
    const theRecord = <CreateCarrierInvoice>{
      // sent in
      shipmentID: this.carrierInvoiceAudit.shipmentID,
      mode: this.carrierInvoiceAudit.mode,
      actualShipDate: this.carrierInvoiceAudit.dateShipped,
      status: this.carrierInvoiceAudit.status,
      lane: this.carrierInvoiceAudit.lane,
      currencyCode: this.carrierForm.get('invoiceCurrency').value,
      mcNumber:
        this.carrierInvoiceAudit.mcNumber === undefined ||
          this.carrierInvoiceAudit.mcNumber === null ||
          this.carrierInvoiceAudit.mcNumber === 'n/a'
          ? ''
          : this.carrierInvoiceAudit.mcNumber,
      dotNumber:
        this.carrierInvoiceAudit.dotNumber === undefined ||
          this.carrierInvoiceAudit.dotNumber === null ||
          this.carrierInvoiceAudit.dotNumber === 'n/a'
          ? ''
          : this.carrierInvoiceAudit.dotNumber,
      carrierCode:
        this.carrierInvoiceAudit.carrierCode === undefined ||
          this.carrierInvoiceAudit.carrierCode === null ||
          this.carrierInvoiceAudit.carrierCode === 'n/a'
          ? ''
          : this.carrierInvoiceAudit.carrierCode,
      scac:
        this.carrierInvoiceAudit.scac === undefined || this.carrierInvoiceAudit.scac === null || this.carrierInvoiceAudit.scac === 'n/a'
          ? ''
          : this.carrierInvoiceAudit.scac,
      // form

      invoiceDate: new Date(`${currencyDate.getFullYear()}-${this.addZero(currencyDate.getMonth() + 1)}-${this.addZero(currencyDate.getDate())}`),
      invoiceNumber: this.carrierForm.get('invoiceNumber').value,
      carrierName: this.carrierInvoiceAudit.carrierName,
      invoiceWeight: this.carrierForm.get('weight').value,
      distance: this.carrierForm.get('distance').value,
      paymentTerms: this.carrierForm.get('paymentMethod').value,
      serviceTotal: this.chargesGrid.getServiceTotal(),
      freightTotal: this.chargesGrid.getFreightTotal(),
      sendCreateEvent: true,
      isSecondary: false,
      isBBill: false,
      bBillAccountNumber: '',
      glCode: '',
    };

    // add the charges
    const charges = this.chargesGrid.getCharges();
    let sequence = 1;
    theRecord.invoiceCharges = charges.map(
      ({ description, freightClass, fakFreightClass, rate, rateCode: rateQualifier, quantity, amount, group, type, ediCode }) => ({
        description,
        freightClass,
        fakFreight: fakFreightClass,
        rate,
        rateQualifier,
        quantity,
        amount,
        group,
        type,
        ediCode,
        sequence: sequence++,
        isMin: false,
        isMax: false,
        isNonTaxable: false,
        weight: 0,
        dimWeight: 0,
      })
    );

    if (theRecord.mode.toUpperCase() === "TL") {
      theRecord.invoiceNumber = theRecord.invoiceNumber + 'B'.repeat(this.parameters.existingInvoiceCountOnShipment || 0);
    }
    return theRecord;
  }

  protected validCharges(): boolean {
    if (this.chargesInEditProgress) {
      return false;
    }
    if (this.chargesGrid == null) {
      return true;
    }
    if (this.chargesGrid.formGroup != null && this.chargesGrid.formGroup.valid === false) {
      return false;
    }
    return true;
  }

  private loadPage() {
    this.todayMaxValidator = new Date(
      `${this.today.getFullYear()}-${this.addZero(this.today.getMonth() + 1)}-${this.addZero(this.today.getDate() + 1)}`);

    if (this.editMode === false) {
      this.loadShipmentAPI();
    } else {
      this.loadFinanceAPI();
    }
  }

  private loadFinanceAPI() {
    this.showSpinner(true, 'Loading finance data...');
    const invoiceRecord = this.pcFinanceService.getInvoiceyID(this.invoiceID);
    const shipmentRecord = this.pcShipmentService.getShipmentByID(this.shipmentID);

    // call them
    const combined = forkJoin([invoiceRecord, shipmentRecord]);
    combined.subscribe(
      (shipmentInvoiceData) => {
        // cleanup null quantities
        if (shipmentInvoiceData[0] && shipmentInvoiceData[0].charges) {
          shipmentInvoiceData[0].charges.forEach((charge) => (charge.quantity = charge.quantity || 0));
        }
        this.carrierInvoiceAudit = new ui.CarrierInvoiceAudit();
        this.carrierInvoiceAudit.setFinanceCarrierInvoices(shipmentInvoiceData[0], shipmentInvoiceData[1]);
        this.isDataloading = false;
        this.quoteTotal = shipmentInvoiceData[1] && shipmentInvoiceData[1].selectedRate ? shipmentInvoiceData[1].selectedRate.carrierTotal : 0;
        this.customerNormalizedTotal = this.carrierInvoiceAudit.invoiceTotal;
        this.carrierTotal = this.carrierInvoiceAudit.invoiceTotal;
        this.showSpinner(false);
        if (this.startupService.showDebug) {
          console.log(shipmentInvoiceData[0]);
          console.log(shipmentInvoiceData[1]);
        }

        // should we load the carriers
        if (this.carrierInvoiceAudit.scac ||
          (this.carrierInvoiceAudit.carrierName &&
            this.carrierInvoiceAudit.carrierName.length > 2)) {
          if (this.isLTL()) {
            this.loadLTLCarriers(
              this.carrierInvoiceAudit.carrierName,
              this.carrierInvoiceAudit.scac
            );
          } else {
            this.loadTruckloadCarriers(shipmentInvoiceData[1]);
          }
        } else {
          this.createForm();
        }
      },
      (error) => {
        this.loadingMessage = 'Error Loading Page: ' + error.message;
        this.alertMessage.showAlertMessage('Error loading the carrier page', 'Error');
      }
    );
  }
  private loadShipmentAPI() {
    this.showSpinner(true, 'Loading shipment data...');
    const records = this.pcShipmentService.getShipmentByID(this.shipmentID);

    // call them
    const combined = forkJoin([records]);
    combined.subscribe(
      (shipmentData) => {
        this.carrierInvoiceAudit = new ui.CarrierInvoiceAudit();
        this.carrierInvoiceAudit.setShipmentCarrierInvoices(shipmentData[0], this.helpers);
        this.carrierInvoiceAudit.status = 'New';
        this.carrierInvoiceAudit.dateCreated = new Date();
        this.isDataloading = false;
        this.showSpinner(false);
        this.customerNormalizedTotal = shipmentData[0].selectedRate.carrierTotal;
        this.carrierTotal = shipmentData[0].selectedRate.carrierTotal;
        this.shipmentDTO = shipmentData[0];

        // left in to help QA testing
        if (this.startupService.showDebug) {
          console.log(shipmentData[0]);
        }

        // should we load the carriers
        if (
          this.carrierInvoiceAudit.scac ||
          (this.carrierInvoiceAudit.carrierName &&
            this.carrierInvoiceAudit.carrierName.length > 2)
        ) {
          if (this.isLTL()) {
            this.loadLTLCarriers(
              this.carrierInvoiceAudit.carrierName,
              this.carrierInvoiceAudit.scac
            );
          } else {
            this.loadTruckloadCarriers(shipmentData[0]);
          }
        } else {
          this.createForm();
        }
      },
      (error) => {
        this.loadingMessage = 'Error Loading Page: ' + error.message;
        this.alertMessage.showAlertMessage('Error loading the carrier page', 'Error');
      }
    );
  }
  protected isLTLOrInternational(): boolean {
    return (
      this.carrierInvoiceAudit.mode.toUpperCase() === 'LTL' ||
      this.carrierInvoiceAudit.mode.toUpperCase() === 'LTL VOLUME' ||
      this.carrierInvoiceAudit.mode.toUpperCase() === 'VOLUME' ||
      this.carrierInvoiceAudit.mode.toUpperCase() === 'INTL GROUND' ||
      this.carrierInvoiceAudit.mode.toUpperCase() === 'AIR' ||
      this.carrierInvoiceAudit.mode.toUpperCase() === 'OCEAN' ||
      this.carrierInvoiceAudit.mode.toUpperCase() === 'FINAL MILE' ||
      this.carrierInvoiceAudit.mode.toUpperCase() === 'BILL PAY'
    );
  }
  protected isLTL(): boolean {
    return (
      this.carrierInvoiceAudit.mode.toUpperCase() === 'LTL' ||
      this.carrierInvoiceAudit.mode.toUpperCase() === 'LTL VOLUME' ||
      this.carrierInvoiceAudit.mode.toUpperCase() === 'VOLUME'
    );
  }
  private loadLTLCarriers(carrierName: string, scac: string) {
    if (scac) {
      this.loadLTLSCACCarriers(scac);
    } else {
      this.loadLTLNameCarriers(carrierName);
    }
  }
  private loadLTLNameCarriers(carrierName: string) {
    // are we already searching
    if (this.appSpinner.loading === true) {
      return;
    }
    this.showSpinner(true, 'Looking up carriers...');
    this.isLoadingCarriers = true;
    this.pcFinanceService.getLtlCarriers(carrierName).subscribe(
      (data) => {
        this.controlCarrierList = this.carrierList = data as CarrierResponse[];
        this.carrierList.sort((a, b) => (a.carrierName > b.carrierName ? 1 : -1));
        if (this.carrierForm == null) {
          this.createForm();
        }
        this.stopLoading();
      },
      (error) => {
        this.stopLoading();
        this.loadingMessage = 'Error Loading Page: ' + error.message;
        this.alertMessage.showAlertMessage('Error loading the LTL carriers', 'Error');
        if (this.carrierForm == null) {
          this.createForm();
        }
      }
    );
  }

  private loadLTLSCACCarriers(scac: string) {
    // exact match
    if (this.appSpinner.loading === true) {
      return;
    }
    this.showSpinner(true, 'Looking up carriers...');
    this.isLoadingCarriers = true;
    this.pcFinanceService.getLtlSCACCarriers(scac).subscribe(
      (data) => {
        this.stopLoading();

        this.controlCarrierList = this.carrierList = data as CarrierResponse[];
        if (data.length === 1) {
          this.carrierInvoiceAudit.carrierName = data[0].carrierName;
        }
        if (this.carrierForm == null) {
          this.createForm();
        }
      },
      (error) => {
        this.stopLoading();
        this.loadingMessage = 'Error Loading Page: ' + error.message;
        this.alertMessage.showAlertMessage('Error loading the LTL carriers', 'Error');
        if (this.carrierForm == null) {
          this.createForm();
        }
      }
    );
  }

  private loadTruckloadCarriers(shipmentDTO: ShipmentDTO) {
    let carrierResponse = new CarrierResponse(0,
      shipmentDTO.selectedRate.carrierName,
      shipmentDTO.selectedRate.scac,
      shipmentDTO.selectedRate.mcNumber,
      shipmentDTO.selectedRate.dotNumber,
      shipmentDTO.selectedRate.carrierCode,
      null, null);

    this.carrierList = [];

    this.carrierList.push(carrierResponse);
    this.filterCarriers(shipmentDTO.carrierName);

    if (this.carrierForm == null) {
      this.createForm();
    }
  }
  private stopLoading() {
    this.isLoadingCarriers = false;
    this.isDataloading = false;
    this.showSpinner(false);
  }
  private showSpinner(show: boolean, text: string = '') {
    this.appSpinner.loading = show;
    this.appSpinner.text = text;
  }

  public get submitDisabled(): boolean {
    return this.submittingForm || (!this.isLTLOrInternational() && this.carrierInvoiceAudit.carrierCode === 'n/a')
      || this.carrierForm === null
      || this.carrierForm.invalid
      || !this.validCharges()
  }
}
