import { AuthOperation } from "@/auth/AuthOperation";

export enum MenuTypes {
  Link,
  Category,
}

export class MenuItem {
  Label: String;
  URL: String;
  routerLink: Array<string>;
  operations?: Array<AuthOperation | string>;
  type: MenuTypes;
  Children: Array<MenuItem>;
}

export let InitialData = new Array<MenuItem>();

const Track: MenuItem[] = [
  {
    Label: 'Invoices',
    URL: '#',
    routerLink: ['invoices/unmatched'],
    operations: [AuthOperation.CanWorkUnmatchedInvoices],
    type: MenuTypes.Link,
    Children: [],
  },
  {
    Label: 'Bad Debt',
    URL: '#',
    routerLink: ['bad-debt'],
    operations: [AuthOperation.CanViewCollection, AuthOperation.CanWorkCollection],
    type: MenuTypes.Link,
    Children: [],
  },
  {
    Label: 'Customer Profile',
    URL: '#',
    routerLink: null,
    operations: [AuthOperation.CanViewCustomerProfile, AuthOperation.CanEditCustomerProfile],
    type: MenuTypes.Category,
    Children: [
      {
        Label: 'Customer Profiles',
        URL: '#',
        routerLink: ['customer-profile'],
        type: MenuTypes.Link,
        Children: [],
      },
      {
        Label: 'Approve to Pay',
        URL: '#',
        routerLink: ['approve-to-pay'],
        type: MenuTypes.Link,
        Children: [],
      },
    ],
  },
  {
    Label: 'Proactive',
    URL: '',
    operations: [AuthOperation.CanEditProactive],
    routerLink: null,
    type: MenuTypes.Category,
    Children: [
      {
        Label: 'Manual',
        URL: '#',
        routerLink: ['proactive/manual'],
        type: MenuTypes.Link,
        Children: [],
      },
      {
        Label: 'Hold',
        URL: '#',
        routerLink: ['proactive/hold'],
        type: MenuTypes.Link,
        Children: [],
      },
    ],
  },
  {
    Label: 'Truckload',
    URL: '',
    operations: [AuthOperation.CanEditTLDocProactive],
    routerLink: null,
    type: MenuTypes.Category,
    Children: [
      {
        Label: 'TL Audit',
        URL: '#',
        routerLink: ['truckload-audit'],
        type: MenuTypes.Link,
        Children: [],
      },
    ],
  },
  {
    Label: 'Audit',
    URL: '',
    operations: [AuthOperation.InvoiceAudit],
    routerLink: null,
    type: MenuTypes.Category,
    Children: [
      {
        Label: 'Audit Queues',
        URL: '#',
        routerLink: ['audit-queue'],
        type: MenuTypes.Link,
        Children: [],
      },
      {
        Label: 'Document Queue',
        URL: '#',
        routerLink: ['audit-queue/document'],
        type: MenuTypes.Link,
        Children: [],
      },
      {
        Label: 'Carrier Queue',
        URL: '#',
        routerLink: ['audit-queue/carrier'],
        type: MenuTypes.Link,
        Children: [],
      },
      {
        Label: 'Return Queue',
        URL: '#',
        routerLink: ['audit-queue/return'],
        type: MenuTypes.Link,
        Children: [],
      },
      {
        Label: 'Secondary Queue',
        URL: '#',
        routerLink: ['audit-queue/secondary'],
        type: MenuTypes.Link,
        Children: [],
      },
    ],
  },
  {
    Label: 'Invoice Visibility',
    URL: '#',
    operations: [AuthOperation.CanAccess_InvoiceVisibility],
    routerLink: null,
    type: MenuTypes.Category,
    Children: [{
      Label: 'Invoice Aging',
      URL: '#',
      routerLink: ['invoice-aging'],
      type: MenuTypes.Link,
      Children: [],
    },
    {
      Label: 'Daily Summary',
      URL: '#',
      operations: [AuthOperation.CanAccess_InvoiceVisibilityDailySummary],
      routerLink: ['invoices-summary'],
      type: MenuTypes.Link,
      Children: [],
    },
    {
      Label: 'Duplicate Carriers',
      URL: '#',
      operations: [AuthOperation.CanAccess_InvoiceVisibilityDuplicateCarriers],
      routerLink: ['duplicate-invoices'],
      type: MenuTypes.Link,
      Children: [],
    },
    {
      Label: "Raw Invoice Search",
      URL: "#",
      routerLink: ["raw-invoice-search"],
      type: MenuTypes.Link,
      Children: [],
    }]
  }
];

InitialData = Track;
