import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SuggestedShipmentDetails, SuggestedShipmentsAddress } from '../../models/SuggestedShipments';
import { PendingAddressResponse, PendingCarrierInvoice } from '../../services/Invoice';
import { InvoiceService } from '../../services/invoice.service';
import { TrackingService } from '../../services/tracking.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { SortDescriptor, orderBy, process, State, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { StatePersistingService } from '../../services/state-persisting-service.service';
import { GridSettings } from '../../services/grid-setting';

type SuggestedMatchesGridData = Omit<SuggestedShipmentDetails, 'targetShip'> & {
  matchPercentage: number;
  targetShip?: Date;
}

@Component({
  selector: 'suggested-shipment-matches',
  templateUrl: './suggested-shipment-matches.component.html',
  styleUrls: ['./suggested-shipment-matches.component.scss'],
})
export class SuggestedShipmentMatchesComponent implements OnInit {
  @Input() targetInvoice: PendingCarrierInvoice;
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;
  suggestedMatches: SuggestedMatchesGridData[] = [];
  pageSize = 25;
  sort: SortDescriptor[] = [];

  loadingSuggestions = false;
  @Output() matched = new EventEmitter();

  public gridSettings: GridSettings = {
    state: {
      skip: 0,
      take: this.pageSize,
      sort: [{ field: 'matchPercentage', dir: 'desc' }],
      // Initial filter descriptor
      filter: {
        logic: "or",
        filters: [],
      }
    },
    gridData: process(this.suggestedMatches, {
      skip: 0,
      take: 5,

      // Initial filter descriptor
      filter: {
        logic: "and",
        filters: [],
      },
    })
  };

  constructor(private trackingService: TrackingService,
    private persistingService: StatePersistingService,
    private invoiceService: InvoiceService) { }

  ngOnInit() {
    const originZip = this.targetInvoice.origin.zip;
    const destinationZip = this.targetInvoice.destination.zip;

    let bl_Ref = '';
    let bol_Ref = '';
    if (this.targetInvoice.references) {
      let blArray = this.targetInvoice.references.filter((x) => x.type === 'BL #' && !x.isPrimary).map((x) => x.value);
      bl_Ref = blArray.join(' | ') || '';

      let bolArray = this.targetInvoice.references.filter((x) => x.type === 'BOL' && !x.isPrimary).map((x) => x.value);
      bol_Ref = bolArray.join(' | ') || '';
    }

    let totalQuantity = 0;
    let totalWeight = 0;
    if (this.targetInvoice.items) {
      this.targetInvoice.items.forEach((item) => {
        totalQuantity += item.quantity.value;
        totalWeight += item.weight.value;
      });
    };

    const pickupDateString = String(this.targetInvoice.pickupDate);

    this.trackingService.getSuggestedShipmentsByOriginDestination(
      originZip,
      destinationZip,
      this.targetInvoice.scac,
      pickupDateString,
      totalQuantity,
      totalWeight,
      bl_Ref,
      bol_Ref,
    ).subscribe((suggestedMatchDtos) => {
      this.suggestedMatches = suggestedMatchDtos.map((suggestedMatchDto) => ({
        ...suggestedMatchDto.shipment,
        matchPercentage: suggestedMatchDto.matchPercentage,
        targetShip: suggestedMatchDto.shipment.targetShip
          ? new Date(suggestedMatchDto.shipment.targetShip)
          : undefined,
      }));

      this.setGridSettings();
    });
  }


  private setGridSettings() {
    const gridName = this.queueName();
    const gridSettings: GridSettings =
      this.persistingService.get(gridName);

    if (gridSettings !== null && this.suggestedMatches.length > 0) {
      this.gridSettings = this.mapGridSettings(gridSettings);
    } else {
      this.gridSettings = this.mapGridSettings(this.defaultGridState());
    }
  }

  private defaultGridState() {
    const defaultState: GridSettings = {
      state: {
        skip: 0,
        take: this.pageSize,
        sort: [{ field: 'matchPercentage', dir: 'desc' }],
        // Initial filter descriptor
        filter: {
          logic: "or",
          filters: [],
        }
      },
      gridData: process(this.suggestedMatches, {
        skip: 0,
        take: 5,

        // Initial filter descriptor
        filter: {
          logic: "and",
          filters: [],
        },
      })
    };
    return defaultState;
  }

  public mapGridSettings(gridSettings: GridSettings): GridSettings {
    const state = gridSettings.state;
    this.mapDateFilter(state.filter);

    return {
      state,
      gridData: process(this.suggestedMatches, state),
    };
  }

  onDataStateChange(state: DataStateChangeEvent) {
    this.gridSettings.state = state;
    this.saveGrid();
    this.setGridSettings();
  }

  onPageChange(event: PageChangeEvent): void {
    this.gridSettings.state.skip = event.skip;
    this.pageSize = event.take;
    this.setGridSettings();
  }

  async onApplyShipment(e: any, shipment: SuggestedMatchesGridData) {
    const btn = e.target.closest('button') as HTMLButtonElement;
    btn.classList.add('loading');
    await this.matchToShipment(shipment);
    btn.classList.remove('loading');
  }

  async matchToShipment(shipment: SuggestedMatchesGridData) {
    try {
      await this.invoiceService.matchInvoice(this.targetInvoice.id, shipment.primaryReference, shipment.shipmentID).toPromise();
      this.matched.emit({ invoice: this.targetInvoice, matchedPrimaryReference: shipment.primaryReference });
    } catch (err) {
      this.alertMessage.showAlertMessage(`Error matching invoice to shipment ${shipment.primaryReference}`, 'Error');
    }
  }

  private mapInvoiceAddressToSuggestedShipmentAddress(
    address: PendingAddressResponse,
  ): SuggestedShipmentsAddress {
    return {
      name: address.name || undefined,
      address1: address.address || undefined,
      address2: address.addressLine2 || undefined,
      city: address.city || undefined,
      stateProvince: address.state || undefined,
      postalCode: address.zip || undefined,
    };
  }

  private queueName(): string {
    return 'suggested-shipments-for-invoices';
  }

  private saveGrid(): void {
    const gridName = this.queueName();
    const gridConfig = {
      state: this.gridSettings.state,
    };
    this.persistingService.set(gridName, gridConfig);
  }

  private mapDateFilter = (descriptor: any) => {
    const filters = descriptor.filters || [];

    filters.forEach((filter) => {
      if (filter.filters) {
        this.mapDateFilter(filter);
      } else if (filter.field === "targetShip" && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  };
}
