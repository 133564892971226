import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, from, throwError as observableThrowError } from 'rxjs';
import { AuthService } from '@/auth/auth.service';
import { StartupService } from '@/startup.service';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { TruckloadBBill, ResetTLInvoice, ResetTLInvoiceResponse } from '@/models/Truckload';
import {
  PendingCarrierInvoice,
  UpdatePendingInvoiceAction,
  PendingCarrierInvoiceStatus,
  SavePendingInvoice,
  UpdateCarrierInvoice,
  UpdateAuditorResponse,
  ReopenQueue,
  CarrierInvoiceItem,
  CarrierInvoiceRaw,
  CarrierInvoiceSummary,
} from '@/services/Invoice';
import { ShipmentInvoice } from '@/models/Shipment';
import {
  AuditNoteDetailResponse,
  AuditUserResponse,
  ShipmentInvoiceNoDetailResponse,
  CarrierEmailSentHistory,
} from '@/services/InvoiceAudit';
import { ShipmentInvoiceAuditUI } from '@/pages/shipment-invoice-audit/ShipmentInvoiceAudit.ui';
import ui = ShipmentInvoiceAuditUI;
import { IDatesDuplicateCarrier, IDeleteDuplicateCarrier, IDuplicateCarrier, IResetDuplicateCarrier } from '@/pages/duplicate-carrier/duplicate-carrier.interface';
import { InvoiceMethodEnum } from '@/models/InvoiceMethodEnum';
import { IAgedCarrierInvoiceRequest, IDetailedSummary, IFormDateCtrl } from '@/pages/invoices-summary/invoices-summary.interface';
import { IAgedCarrierInvoices, IAgedCarrierInvoiceStatusCount } from '@/models/invoice-reports.interface';
import { PendingInvoiceReference } from '@/models/ShipmentInvoiceAudit';
import { EDIChargeMap } from '@/models/EDIChargeMap';
import { SpecialImportResponse } from '@/models/SpecialImport';

@Injectable()
export class InvoiceService {
  shipmentApiHeaders: HttpHeaders;
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  public pendingCarrierInvoiceBook: PendingCarrierInvoice[];
  public pendingCarrierInvoice: PendingCarrierInvoice;

  private revertToManualInvoiceSubject: BehaviorSubject<object>;

  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) {
    this.shipmentApiHeaders = new HttpHeaders({
      //      EnterpriseID: '7',
      EnterpriseID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,
      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
      UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.name : null,
    });

    this.revertToManualInvoiceSubject = new BehaviorSubject<object>(null);
  }

  public getReferenceTypes(): Observable<any> {
    return this.http
      .get(`${this.startupService.financeAPIUrl}v2/pending-invoices/referencetypes`)
      .pipe(catchError(this.handleError));
  }

  public getReferences(invoiceId: number): Observable<any> {
    return this.http
      .get(`${this.startupService.financeAPIUrl}v2/pending-invoices/${invoiceId}/references`)
      .pipe(catchError(this.handleError));
  }

  public saveReference(invoiceId: number, reference: PendingInvoiceReference): Observable<any> {
    const url = `${this.startupService.financeAPIUrl}v2/pending-invoices/${invoiceId}/references`;
    return this.http.post(url, reference);
  }

  public updateReference(invoiceId: number, reference: any): Observable<any> {
    const url = `${this.startupService.financeAPIUrl}v2/pending-invoices/${invoiceId}/references/${reference.id}`;
    return this.http.put(url, reference);
  }

  public deleteReference(invoiceId: number, referenceId: number): Observable<any> {
    const url = `${this.startupService.financeAPIUrl}v2/pending-invoices/${invoiceId}/references/${referenceId}`;
    return this.http.delete(url);
  }

  public getNotes(invoiceId: number): Observable<any> {
    const url = `${this.startupService.financeAPIUrl}v2/pending-invoices/${invoiceId}/notes`;
    return this.http.get(url);
  }

  public postNote(invoiceId: number, note: string): Observable<any> {
    const url = `${this.startupService.financeAPIUrl}v2/pending-invoices/${invoiceId}/notes`;
    return this.http.post(url, { "note": note });
  }

  // invoices
  public deletePendingInvoice(carrierInvoiceId: number): Observable<Object> {
    return this.http
      .delete(`${this.startupService.financeAPIUrl}v2/pending-invoices/${carrierInvoiceId}`)
      .pipe(catchError(this.handleError));
  }

  public UnmatchedInvoice(carrierInvoiceId: number, saveInvoice: SavePendingInvoice) {
    return this.http.patch(`${this.startupService.financeAPIUrl}v2/pending-invoices/${carrierInvoiceId}`, saveInvoice).pipe(
      map((response: PendingCarrierInvoice) => {
        this.pendingCarrierInvoice = response;
      }),
      catchError(this.handleError)
    );
  }

  matchInvoice(carrierInvoiceId: number, selectedPrimaryReference: string, shipmentId: number) {
    const endpoint = `${this.startupService.financeAPIUrl}v2/pending-invoices/${carrierInvoiceId}`;
    const savePendingInvoice = new SavePendingInvoice();

    savePendingInvoice.invoiceID = carrierInvoiceId;
    savePendingInvoice.selectedPrimaryReference = selectedPrimaryReference;
    savePendingInvoice.shipmentID = shipmentId;
    savePendingInvoice.updatePendingInvoiceAction = UpdatePendingInvoiceAction.SetShipment;
    savePendingInvoice.status = PendingCarrierInvoiceStatus[PendingCarrierInvoiceStatus.PendingCarrierInvoiceReceived];

    return this.http.patch(endpoint, savePendingInvoice);
  }

  public RevertInvoiceToManual(invoiceID: number, analogousID: number, shipmentID: number): void {
    const requestBody = {
      shipmentID: shipmentID,
      status: 'Manual',
      isSecondary: false,
      updateCarrierAction: 'Update',
    };

    this.http
      .post(`${this.startupService.financeAPIUrl}v2/invoices/${invoiceID}/carrier/revertToManual`, requestBody)
      .pipe(catchError(this.handleError))
      .subscribe(
        () => {
          this.revertToManualInvoiceSubject.next({
            isSuccess: true,
            method: InvoiceMethodEnum.RevertInvoiceToManual,
            invoiceID: invoiceID,
            analogousID: analogousID,
          });
        },
        (error) => {
          this.revertToManualInvoiceSubject.next({
            isSuccess: false,
            method: InvoiceMethodEnum.RevertInvoiceToManual,
            invoiceID: invoiceID,
            analogousID: analogousID,
            error: error,
          });
        }
      );
  }

  public getPendingInvoiceByInvoiceID(invoiceID: number, analogousID: number): void {
    this.http
      .get(`${this.startupService.financeAPIUrl}v2/pending-invoices/${invoiceID}/pending-invoices`)
      .pipe(catchError(this.handleError))
      .subscribe(
        (response: any[]) => {
          const pendingInvoice = response.shift();
          this.revertToManualInvoiceSubject.next({
            isSuccess: true,
            method: InvoiceMethodEnum.GetPendingInvoiceByInvoiceID,
            invoiceID: pendingInvoice.id,
            analogousID: invoiceID,
          });
        },
        (error) => {
          this.revertToManualInvoiceSubject.next({
            isSuccess: false,
            method: InvoiceMethodEnum.GetPendingInvoiceByInvoiceID,
            invoiceID: invoiceID,
            analogousID: analogousID,
            error: error,
          });
        }
      );
  }

  public revertToManualInvoiceObservable(): Observable<object> {
    return this.revertToManualInvoiceSubject.asObservable();
  }

  public DeletePendingInvoiceErrors(carrierInvoiceId: number) {
    return this.http.delete(`${this.startupService.financeAPIUrl}v2/pending-invoices/${carrierInvoiceId}/errors`).pipe(
      map((response: PendingCarrierInvoice) => {
        this.pendingCarrierInvoice = response;
      }),
      catchError(this.handleError)
    );
  }

  public getAllInvoicesForShipment(shipmentID: number): Observable<ShipmentInvoice[]> {
    const url = `${this.startupService.financeAPIUrl}v2/shipments/${shipmentID}/invoices`;
    return this.http
      .get<ShipmentInvoice[]>(url, {
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.handleError));
  }

  public getInvoicesByStatus(statusList: string[], mode: string = null): Observable<PendingCarrierInvoice[]> {
    let url = mode
      ? `${this.startupService.financeAPIUrl}v2/pending-invoices/${mode}/find`
      : `${this.startupService.financeAPIUrl}v2/pending-invoices/find`;

    return this.http
      .get<PendingCarrierInvoice[]>(url, {
        headers: this.httpOptions.headers,
        params: { statusList: statusList },
      })
      .pipe(
        map((data) => (this.pendingCarrierInvoiceBook = data)),

        catchError(this.handleError)
      );
  }

  public updateAuditor(auditor: UpdateCarrierInvoice, customerInvoiceID: number): Observable<UpdateAuditorResponse> {
    return this.http.patch<UpdateAuditorResponse>(`${this.startupService.financeAPIUrl}v2/invoices/${customerInvoiceID}/carrier`, auditor);
  }

  public getAuditors(): Observable<AuditUserResponse[]> {
    return this.http
      .get<AuditUserResponse[]>(`${this.startupService.financeAPIUrl}v2/user/auditors`, {
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.handleError));
  }

  public getCarrierEmailSentHistory(shipmentID: number): Observable<CarrierEmailSentHistory[]> {
    return this.http
      .get<CarrierEmailSentHistory[]>(`${this.startupService.financeAPIUrl}v2/audit-invoices/carriers/${shipmentID}/email-history`, {
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.handleError));
  }

  public getCarrierInvoicesByID(shipmentID: number): Observable<ShipmentInvoiceNoDetailResponse[]> {
    return this.http
      .get<ShipmentInvoiceNoDetailResponse[]>(`${this.startupService.financeAPIUrl}v2/shipments/${shipmentID}/audit-invoices`, {
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.handleError));
  }

  public getInvoiceByID(id: number): Observable<PendingCarrierInvoice> {
    return this.http.get<PendingCarrierInvoice>(`${this.startupService.financeAPIUrl}v2/pending-invoices/${id}`).pipe(
      map((data) => (this.pendingCarrierInvoice = data)),
      catchError(this.handleError)
    );
  }

  public getInvoiceByInvoiceID(invoiceId: number): Observable<PendingCarrierInvoice> {
    return this.http.get<PendingCarrierInvoice[]>(`${this.startupService.financeAPIUrl}v2/pending-invoices/${invoiceId}/pending-invoices`)
      .pipe(mergeMap(x => x.filter(y => !!y)));
  }

  public getAuditNotes(id: number): Observable<AuditNoteDetailResponse[]> {
    return this.http
      .get<AuditNoteDetailResponse[]>(`${this.startupService.financeAPIUrl}v2/shipments/${id}/audit-notes`)
      .pipe(catchError(this.handleError));
  }

  // audit invoice functions
  public reOpenQueue(invoiceAuditID: number, reopenQueue: ReopenQueue): Observable<AuditNoteDetailResponse[]> {
    return this.http
      .post<AuditNoteDetailResponse[]>(`${this.startupService.financeAPIUrl}v2/audit-invoices/${invoiceAuditID}/reopen`, reopenQueue)
      .pipe(catchError(this.handleError));
  }

  public auditInvoiceTotals(carrierInvoicesCharges: ui.Charge[], carrierQuoteCharges: ui.Charge[]) {
    if (carrierQuoteCharges == null || carrierInvoicesCharges == null) {
      return;
    }
    for (const charge of carrierInvoicesCharges) {
      charge.error = carrierQuoteCharges.find((x) => x.total === charge.total) == null ? true : false;
    }
  }
  public auditCalculateTotalCostVariance(carrierInvoice: ui.CarrierInvoice, carrierQuote: ui.CarrierQuote): number {
    return carrierQuote.grandTotal - carrierInvoice.grandTotal;
  }
  public auditCalculateSubtotalCostVariance(carrierInvoice: ui.CarrierInvoice, carrierQuote: ui.CarrierQuote): number {
    return carrierQuote.subTotal - carrierInvoice.subTotal;
  }

  public auditScacVariance(carrierInvoice: ui.CarrierInvoice, carrierQuote: ui.CarrierQuote): string {
    let hasErrors = false;
    let fedExScacs = ['FXFE', 'FXNL', 'FXFC'];
    let forwardAirScacs = ['FWRA', 'FWDA'];
    let yrcScacs = ['RDWY', 'RETL', 'RDTC', 'HMES', 'NPME'];

    if (!carrierInvoice.scac || !carrierQuote.scac) {
      hasErrors = false;
    } else if (carrierInvoice.scac === carrierQuote.scac) {
      hasErrors = false;
    } else if (fedExScacs.includes(carrierInvoice.scac) && fedExScacs.includes(carrierQuote.scac)) {
      hasErrors = false;
    } else if (forwardAirScacs.includes(carrierInvoice.scac) && forwardAirScacs.includes(carrierQuote.scac)) {
      hasErrors = false;
    } else if (yrcScacs.includes(carrierInvoice.scac) && yrcScacs.includes(carrierQuote.scac)) {
      hasErrors = false;
    } else {
      hasErrors = true;
    }

    return hasErrors ? `SCAC variance - Invoice: ${carrierInvoice.scac} | Shipment: ${carrierQuote.scac}` : '';
  }

  public auditMCNumberVariance(carrierInvoice: ui.CarrierInvoice, carrierQuote: ui.CarrierQuote): string {
    if (!carrierInvoice.mcNumber && !carrierQuote.mcNumber) {
      return '';
    }
    return !(carrierInvoice.mcNumber === carrierQuote.mcNumber)
      ? `MC number variance - Invoice: ${carrierInvoice.mcNumber} | Shipment: ${carrierQuote.mcNumber}`
      : '';
  }
  public auditDOTNumberVariance(carrierInvoice: ui.CarrierInvoice, carrierQuote: ui.CarrierQuote): string {
    if (!carrierInvoice.dotNumber && !carrierQuote.dotNumber) {
      return '';
    }
    return !(carrierInvoice.dotNumber === carrierQuote.dotNumber)
      ? `DOT number variance - Invoice: ${carrierInvoice.dotNumber} | Shipment: ${carrierQuote.dotNumber}`
      : '';
  }
  public auditCarrierCodeNumberVariance(carrierInvoice: ui.CarrierInvoice, carrierQuote: ui.CarrierQuote): string {
    if (!carrierInvoice.carrierCode && !carrierQuote.carrierCode) {
      return '';
    }
    return !(carrierInvoice.carrierCode === carrierQuote.carrierCode)
      ? `Carrier Code mismatch - Invoice: ${carrierInvoice.carrierCode || 'no code'} | Shipment: ${carrierQuote.carrierCode || 'no code'}`
      : '';
  }
  public auditCalculateWeightVariance(mode: string, totalInvoiceWeight: number, totalQuoteWeight: number): number {
    if (mode !== 'LTL') {
      return 0;
    }

    // we only care about weight over 5
    const difference = Math.abs(totalInvoiceWeight - totalQuoteWeight);
    if (difference > 5) {
      return difference;
    }
    return 0;
  }

  public convertToUnmatched(id: number, referenceNumber: string): any {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/invoices/${id}/unmatched`, { referenceNumber: referenceNumber });
  }

  // helpers
  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }

  public moveInvoice(shipment: UpdateCarrierInvoice, carrierInvoiceID: number) {
    return this.http.patch(`${this.startupService.financeAPIUrl}v2/invoices/${carrierInvoiceID}/carrier`, shipment);
  }
  public createRevisedInvoice(id: number) {
    return this.http.patch(`${this.startupService.financeAPIUrl}v2/invoices/${id}/carrier/revised`, null).pipe(catchError(this.handleError));
  }
  public createPrimaryInvoice(id: number) {
    return this.http.patch(`${this.startupService.financeAPIUrl}v2/invoices/${id}/carrier/primary`, null).pipe(catchError(this.handleError));
  }

  public deleteInvoice(id: number) {
    return this.http.delete(`${this.startupService.financeAPIUrl}v2/invoices/${id}/carrier`).pipe(catchError(this.handleError));
  }
  public createBBill(shipment: UpdateCarrierInvoice, carrierInvoiceID: number) {
    return this.http.patch(`${this.startupService.financeAPIUrl}v2/invoices/${carrierInvoiceID}/carrier`, shipment);
  }
  public createTruckloadBBill(bbill: TruckloadBBill, carrierInvoiceID: number) {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${carrierInvoiceID}/bbill`, bbill);
  }

  public convertInvoices(carrierInvoices: any[]): any {
    return this.http.post(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/convert`,
      carrierInvoices,
      {
        headers: this.httpOptions.headers
      });
  }

  public resetTruckloadInvoices(invoices: ResetTLInvoice[]) {
    return this.http.post<ResetTLInvoiceResponse[]>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/reset`, invoices);
  }

  public getDuplicateCarrier(date: IDatesDuplicateCarrier): Observable<Array<IDuplicateCarrier>> {
    return this.http.post<Array<IDuplicateCarrier>>(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/duplicates`, date);
  }

  public resetDuplicateCarrier(data: IResetDuplicateCarrier[]): Observable<boolean> {
    return this.http.post<boolean>(`${this.startupService.financeAPIUrl}v2/pending-invoices/reset`, data);
  }
  public deleteDuplicateCarrier(data: IDeleteDuplicateCarrier): Observable<boolean> {
    return this.http.post<boolean>(`${this.startupService.financeAPIUrl}v2/pending-invoices/deletemany`, data);
  }

  public getInvoiceDetailedSummary(date: IFormDateCtrl): Observable<Array<IDetailedSummary>> {
    return this.http.post<Array<IDetailedSummary>>(`${this.startupService.financeAPIUrl}v2/pending-invoices/detailedsummary`, date, {
      headers: this.httpOptions.headers,
    });
  }

  public getAgedCarrierInvoices(request: IAgedCarrierInvoiceRequest): Observable<Array<IAgedCarrierInvoices>> {
    return this.http.post<Array<IAgedCarrierInvoices>>(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/agedcarrierinvoices`,
      request,
      { headers: this.httpOptions.headers }
    );
  }

  public getAgedCarrierInvoiceStatusCount(date: IFormDateCtrl): Observable<Array<IAgedCarrierInvoiceStatusCount>> {
    return this.http.post<Array<IAgedCarrierInvoiceStatusCount>>(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/agedcarrierinvoicestatuscount`,
      date,
      { headers: this.httpOptions.headers }
    );
  }

  public getCarrierInvoiceItems(carrierInvoiceId: number): Observable<Array<CarrierInvoiceItem>> {
    return this.http.get<Array<CarrierInvoiceItem>>(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/${carrierInvoiceId}/items`,
      { headers: this.httpOptions.headers }
    );
  }

  public getRawCarrierInvoices(invoiceNumber: string): Observable<Array<CarrierInvoiceRaw>> {
    return this.http.get<Array<CarrierInvoiceRaw>>(
      `${this.startupService.financeAPIUrl}v2/invoices/raw-invoice/${invoiceNumber}`,
      { headers: this.httpOptions.headers }
    );
  }

  public getCarrierInvoiceSummary(invoiceNumber: string): Observable<Array<CarrierInvoiceSummary>> {
    return this.http.get<Array<CarrierInvoiceSummary>>(
      `${this.startupService.financeAPIUrl}v2/invoices/carrier-invoice-summary/${invoiceNumber}`,
      { headers: this.httpOptions.headers }
    );
  }

  public createCarrierInvoiceItem(carrierInvoiceId: number, data: CarrierInvoiceItem): Observable<CarrierInvoiceItem> {
    return this.http.post<CarrierInvoiceItem>(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/${carrierInvoiceId}/items`,
      data,
      { headers: this.httpOptions.headers }
    );
  }

  public updateCarrierInvoiceItem(data: CarrierInvoiceItem): Observable<CarrierInvoiceItem> {
    return this.http.put<CarrierInvoiceItem>(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/${data.carrierInvoiceId}/items/${data.id}`,
      data,
      { headers: this.httpOptions.headers }
    );
  }
  public updateCarrierInvoiceStatus(id: number, status: string) {
    return this.http.put(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/${id}/${status}`, null,
      { headers: this.httpOptions.headers }
    );
  }

  public deleteCarrierInvoiceItem(data: CarrierInvoiceItem) {
    return this.http.delete(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/${data.carrierInvoiceId}/items/${data.id}`,
      { headers: this.httpOptions.headers }
    )
  }

  /*
   * Sets the mass conversion flag on the specified invoices.
   */
  public flagCarrierInvoice(ids: number[]): any {
    let params: HttpParams = new HttpParams();

    for (let id of ids) {
      if (params.has('ids')) {
        params = params.append('ids', id.toString());
      }
      else {
        params = params.set('ids', id.toString());
      }
    }

    return this.http.post(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/flag`,
      null,
      {
        headers: this.httpOptions.headers,
        params: params
      });

  }

  /*
   * Unsets the mass conversion flag on the specified invoices.
   */
  public unflagCarrierInvoice(ids: number[]): any {
    let params: HttpParams = new HttpParams();

    for (let id of ids) {
      if (params.has('ids')) {
        params = params.append('ids', id.toString());
      }
      else {
        params = params.set('ids', id.toString());
      }
    }

    return this.http.post(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/unflag`,
      null,
      {
        headers: this.httpOptions.headers,
        params: params
      });
  }

  /*
   * Updates the specified address.
   */
  public updateAddress(id: number, address: any, addressType: string) {
    return this.http.put(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/${id}/addresses/${addressType}`,
      address,
      {
        headers: this.httpOptions.headers
      }
    );
  }

  public MassMoveSecondaryInvoiceCategory(ids: number[], type: string): any {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/invoices/carrier/secondaryCategories`, { SecondaryCategory: type, invoiceIDs: ids });
  }

  public massAssignAuditor(invoiceIds: number[], auditor: string): any {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/invoices/carrier/massAssignAuditor`, { Auditor: auditor, invoiceIDs: invoiceIds });
  }

  public standardizeCarrierInvoiceCharges(invoice: ShipmentInvoiceAuditUI.CarrierInvoice): Observable<ShipmentInvoiceAuditUI.Charge[]> {
    if (!this.startupService.enableStandardizeCharges) {
      return from([invoice.accessorialCharges.concat(invoice.lineHaulCharges)]);
    }

    return this.http.get<EDIChargeMap[]>(`${this.startupService.shipmentApiUrl}v2/reference/edichargesmap`)
      .pipe(map((ediChargeMap: EDIChargeMap[]) => {

        var accessorialCharges = invoice.accessorialCharges.map((charge: ShipmentInvoiceAuditUI.Charge) => {
          var ediMap = ediChargeMap.find(ed => ed.ediStandardCode === charge.ediCode) || { bgStandardCode: 'MSC', bgStandardDescription: 'Other Accessorial Service Charge' };

          return {
            ...charge,
            ediCode: ediMap.bgStandardCode,
            description: ediMap.bgStandardDescription,
          };
        });

        // We still want to map things like DISCOUNT and DEFICIT for edi codes, but not linehaul types. To do that, we leave unmapped codes as is.
        var linehaulCharges = invoice.lineHaulCharges.map((charge: ShipmentInvoiceAuditUI.Charge) => {
          var ediMap = ediChargeMap.find(ed => ed.ediStandardCode == charge.ediCode);

          return {
            ...charge,
            ediCode: ediMap ? ediMap.bgStandardCode : charge.ediCode,
            description: ediMap ? ediMap.bgStandardDescription : charge.description
          }
        });

        return accessorialCharges.concat(linehaulCharges);
      }))
  }

  public uploadSpecialImportFile(file: File, mode: string): Observable<SpecialImportResponse[]> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<SpecialImportResponse[]>(`${this.startupService.financeAPIUrl}v2/imports/${mode}`, formData);
  }
}
