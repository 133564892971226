import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, SelectableSettings, RowClassArgs } from '@progress/kendo-angular-grid';
import { AppState } from '@/appstate.model';
import { Store } from '@ngrx/store';
import { CarrierInvoiceCharge } from '../../models/CarrierInvoiceAudit';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Globals } from '@/_shared/globals';
import { process, State, aggregateBy } from '@progress/kendo-data-query';
import {
  CarrierInvoiceChargesEditMode,
  CarrierInvoiceTotalChanged,
  CarrierInvoiceChargesChanged,
  CarrierInvoiceServiceTotalChanged,
  CarrierInvoiceFreightTotalChanged,
} from '../../actions/carrier-invoice-audit.actions';
import { AuthService } from '@/auth/auth.service';
import { Helpers } from '@/_shared/helpers';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { ConversionHelpers } from '@/_shared/conversionHelper';

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

function trimUpper(str: string | null): string {
  return (str || '').trim().toUpperCase();
}

@Component({
  selector: 'app-invoice-charges',
  templateUrl: './invoice-charges.component.html',
  styleUrls: ['./invoice-charges.component.scss'],
})
export class InvoiceChargesComponent implements OnInit {
  @ViewChild('chargesGrid', { static: false })
  private recordGrid: GridComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  get controls() {
    return this.newChargeFormGroup.controls;
  }

  records: CarrierInvoiceCharge[] = [];
  distance: number;
  title: string;

  formGroup: FormGroup = null;
  descriptions = Globals.Descriptions.map(x => x.name);
  freightClasses = Globals.FreightClasses;
  groupTypes = Globals.GroupTypes;
  qualifiers = Globals.Qualifiers;
  defaultGroupType = {
    group: 'Accessorial',
    displayName: 'Accessorial',
    type: 'ACCESSORIAL',
  };

  deleteRules = [
    {
      group: 'LINEHAUL',
      types: [],
      min: 1,
    },
  ];

  newChargeFormGroup: FormGroup;
  selectableSettings: SelectableSettings;
  editedRowIndex = -1;
  invoiceChargeID = -1;
  gridTotal = 0;
  gridTotalInUSD = 0;
  gridTotalInCAD = 0;
  lastGroup = '';
  theQuoteTotal = 0;
  currentEditCurrency = 'USD';
  conversionRate = 0;
  isInternational = false;
  helpers: Helpers = null;

  constructor(private store: Store<AppState>, private authService: AuthService, private formBuilder: FormBuilder) {
    this.setListeners();
  }
  // grid state
  protected aggregates: any[] = [{ field: 'amount', aggregate: 'sum' }];
  protected state: State = {
    skip: 0,
    take: 50,
    sort: [
      {
        field: 'quanity',
        dir: 'desc',
      },
    ],
    group: [{ field: 'group', aggregates: this.aggregates }],
  };
  protected gridData: any = process(this.records, this.state);
  protected total: any = aggregateBy(this.records, this.aggregates);

  ngOnInit() {
    this.helpers = this.helpers == null ? new Helpers(this.authService) : this.helpers;
    this.title = 'Charges';

    this.createForm();
  }

  private setListeners() {
    this.store
      .select((x) => x.CarrierInvoiceAuditState.charges)
      .subscribe((x) => {
        if ((x || []).length > 0) {
          this.records = x;
          this.setRecords(true);
        }
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.quoteTotal)
      .subscribe((x) => {
        if (x) {
          this.theQuoteTotal = x;
        }
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.distance)
      .subscribe((x) => {
        this.distance = x;
        if (this.records) {
          this.updateDistanceCharges(this.distance);
        }
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.isInternational)
      .subscribe((x) => {
        if (x) {
          this.isInternational = x;
        }
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.conversionRate)
      .subscribe((x) => {
        if (x) {
          this.conversionRate = x;
          this.calculateTotal();
        }
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.editCurrencyCode)
      .subscribe((x) => {
        if (x) {
          this.currentEditCurrency = x;
          this.calculateTotal();
        }
      });
  }
  // grid events
  protected hideRemoveBtn(item: CarrierInvoiceCharge): boolean {
    const itemGroup = trimUpper(item.group);
    const itemType = trimUpper(item.type);
    const hasBrokenRule = this.deleteRules.some((rule) => {
      const ruleGroup = trimUpper(rule.group);
      const ruleTypes = (rule.types || []).map((type: string) => trimUpper(type));
      // if Rule has NO types then ONLY match on Group
      const disregardRuleType = ruleTypes.length === 0;
      if (itemGroup === ruleGroup && (disregardRuleType || ruleTypes.some((ruleType) => ruleType === itemType))) {
        const groupRecords = this.records.filter((record) => {
          const recordGroup = trimUpper(record.group);
          return recordGroup === ruleGroup;
        });
        const matchedRuleRecordCount = groupRecords.reduce((count: number, record) => {
          const recordType = trimUpper(record.type);
          if (disregardRuleType || ruleTypes.some((ruleType) => ruleType === recordType)) {
            ++count;
          }
          return count;
        }, 0);
        return matchedRuleRecordCount <= rule.min;
      }
      return false;
    });
    return hasBrokenRule;
  }
  protected onEditCharge({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.store.dispatch(new CarrierInvoiceChargesEditMode(true));
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.recordGrid.editRow(rowIndex, this.formGroup);
  }
  protected onRemoveCharge(event) {
    // remove the record from the dataset
    this.records = this.records.filter((obj) => obj !== event.dataItem);
    this.gridData = process(this.records, this.state);
    this.calculateTotal();
    this.dispatchChargesChangedEvents();
  }
  protected cancelUpdateCharge({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
    this.store.dispatch(new CarrierInvoiceChargesEditMode(false));
  }
  protected saveChargeHandler({ sender, rowIndex }) {
    // is this really a fuel?
    let type = this.formGroup.value.type;
    if (this.formGroup.value.description === 'Fuel Surcharge' && this.formGroup.value.group === 'Accessorial') {
      type = 'ACCESSORIAL_FUEL';
    }

    // cant have two fuels
    if (this.isMoreThanOneFuel(this.formGroup.value.group, type, +this.formGroup.value.invoiceChargeID)) {
      return false;
    }

    // update the record
    const record = this.records.find(({ invoiceChargeID }) => invoiceChargeID === this.formGroup.value.invoiceChargeID);
    Object.assign(record, this.formGroup.value);

    // set the correct qualifer and type (in case this is really been changed to a fuel)
    record.rateQualifier = this.getQualifierDescription(record.rateCode);
    record.type = type;

    // run through the rules and refresh the grid
    this.runLineItemRules(record);
    this.gridData = process(this.records, this.state);

    // tell the parent that the record has been added to the grid.
    this.calculateTotal();
    this.dispatchChargesChangedEvents();
    this.store.dispatch(new CarrierInvoiceChargesEditMode(false));
    sender.closeRow(rowIndex);
  }
  protected canEditLinehaulDescription(dataItem: any): boolean {
    return dataItem.type === 'DISCOUNT' ? true : false;
  }
  protected canEditAccessorialDescription(dataItem: any): boolean {
    return dataItem.type === 'ACCESSORIAL_FUEL' ? true : false;
  }
  protected groupSelectionChange(value: any): void {
    if (value.group === ' Linehaul' && this.lastGroup !== ' Linehaul') {
      this.controls.description.setValue('');
      this.controls.description.markAsDirty();
    } else if (value.group === 'Accessorial' && this.lastGroup === ' Linehaul') {
      this.controls.description.setValue('Airport Delivery');
    }
    this.controls.group.setValue(value.group);
    this.controls.type.setValue(value.type);
    this.lastGroup = value.group;
  }
  // Page events
  protected insertNewCharge() {
    // create the group name
    const groupName = this.controls.group.value === 'Linehaul' ? ' Linehaul' : this.controls.group.value;

    // is this really a fuel?
    let type = this.controls.type.value;
    if (this.controls.description.value === 'Fuel Surcharge' && groupName === 'Accessorial') {
      type = 'ACCESSORIAL_FUEL';
    }

    // cannot have more than one ACCESSORIAL_FUEL charge
    if (this.isMoreThanOneFuel(groupName, type)) {
      return;
    }

    const newRecord = {
      invoiceChargeID: this.invoiceChargeID--,
      description: this.controls.description.value,
      freightClass: this.controls.freightClass.value,
      fakFreightClass: this.controls.fakFreightClass.value,
      rate: this.controls.rate.value,
      rateQualifier: this.controls.rateQualifier.value,
      rateCode: this.getQualifierCode(this.controls.rateQualifier.value),
      quantity: this.controls.quantity.value,
      group: this.controls.group.value,
      type: type,
      amount: 0,
      weight: 0,
      dimWeight: 0,
      ediCode: '',
      canEdit: true,
      Group: groupName,
      amountInUSD: 0,
      amountInCAD: 0,
    };

    // run through the rules and refresh the grid
    this.records.push(newRecord);
    this.runLineItemRules(newRecord);

    // add to the list
    this.gridData = process(this.records, this.state);
    this.calculateTotal();
    this.dispatchChargesChangedEvents();
  }
  protected rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }
  protected updateDistanceCharges(distance: number) {
    this.records.forEach((record) => {
      if (record.rateCode === 'PM') {
        record.quantity = distance;
        record.amount = record.rate * record.quantity;
        record.amountInCAD = this.getAmountCAD(record.amount);
        record.amountInUSD = this.getAmountUSD(record.amount);
      }
    });
    this.setRecords();
  }
  // helpers
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  private isMoreThanOneFuel(group: string, type: string, invoiceChargeId?: number): boolean {
    if (
      group === 'Accessorial' &&
      type === 'ACCESSORIAL_FUEL' &&
      this.records.some((charge) => charge.type === 'ACCESSORIAL_FUEL' && invoiceChargeId !== +charge.invoiceChargeID)
    ) {
      this.alertMessage.showAlertMessage('Can only have one Fuel Surcharge', 'Error');
      return true;
    }
    return false;
  }
  public disableQuantity(form: boolean): boolean {
    // get the correct values
    let rateCode = '';
    let groupType = '';
    if (form) {
      rateCode = this.formGroup.get('rateCode').value;
      groupType = this.formGroup.get('type').value;
    } else {
      rateCode = this.getQualifierCode(this.controls.rateQualifier.value);
      groupType = this.controls.type.value;
    }

    // disable the controls
    if (
      rateCode === 'FR' ||
      rateCode === 'MN' ||
      rateCode === 'PM' ||
      (rateCode === 'PCT' && groupType === 'DISCOUNT') ||
      (rateCode === 'PCT' && groupType === 'ACCESSORIAL_FUEL')
    ) {
      // zero out the quantity
      if (form) {
        this.formGroup.get('quantity').setValue(0);
      } else {
        this.controls.quantity.setValue(0);
      }
      return true;
    }
    return false;
  }
  private setRecords(firstTime: boolean = false): void {
    this.gridData = process(this.records, this.state);
    this.calculateTotal();
    if (!firstTime) {
      this.dispatchChargesChangedEvents();
    }
  }
  private getQualifierDescription(rateCode: string): string {
    const record = this.qualifiers.find((obj) => {
      return obj.rateCode === rateCode;
    });
    return record.rateQualifier;
  }
  private getQualifierCode(rateDescription: string): string {
    const record = this.qualifiers.find((obj) => {
      return obj.rateQualifier === rateDescription;
    });
    return record.rateCode;
  }
  private runLineItemRules(record: any) {
    // check for distance
    if (record.rateCode === 'PM' && this.distance !== 0) {
      record.quantity = this.distance;
    }
    // rate codes
    if (record.rateCode === 'FR' || record.rateCode === 'MN') {
      record.quantity = 0;
      record.amount = record.rate;
    } else if (record.description.match('^Fuel') != null && record.rateCode === 'PCT') {
      if (record.quantity > 0) {
        record.amount = (record.rate / 100) * record.quantity;
      }
    } else if ((record.rateCode === 'CW') != null && record.rateCode === 'PH') {
      record.amount = record.rate * record.quantity;
    } else if (record.rateCode === 'PCT') {
      record.amount = (record.rate / 100) * record.quantity;
    } else if (record.rateCode === 'PM') {
      record.quantity = this.distance;
      record.amount = record.rate * record.quantity;
    } else {
      record.amount = record.rate * record.quantity;
    }
    record.amountInCAD = this.getAmountCAD(record.amount);
    record.amountInUSD = this.getAmountUSD(record.amount);

    this.processTotals();
  }
  private processTotals() {
    this.updateDiscountRecord();
    this.updateFuelRecord();
  }
  private updateDiscountRecord() {
    // get the last item that is a discount
    let discountRecord = null;
    let index = this.records.length - 1;
    for (; index >= 0; index--) {
      if (this.records[index].type === 'DISCOUNT') {
        discountRecord = this.records[index];
        break;
      }
    }

    if (discountRecord === null || discountRecord.rateCode !== 'PCT') {
      return;
    }

    // get all linehaul items
    const lineHaulItems = this.records.filter(function (e) {
      return e.group === ' Linehaul';
    });

    let tempNoDiscountSum = 0;
    for (index = 0; index < lineHaulItems.length; index++) {
      if (lineHaulItems[index].type !== 'DISCOUNT' && lineHaulItems[index].type !== 'MG_MINMAX_ADJ') {
        tempNoDiscountSum = tempNoDiscountSum + lineHaulItems[index].amount;
      }
    }

    // set the new discount amount
    discountRecord.amount = -1 * tempNoDiscountSum * (discountRecord.rate / 100);
    discountRecord.amountInCAD = this.getAmountCAD(discountRecord.amount);
    discountRecord.amountInUSD = this.getAmountUSD(discountRecord.amount);
  }
  private updateFuelRecord() {
    // get the last item that is a fuel
    let fuelRecord = null;
    let index = this.records.length - 1;
    for (; index >= 0; index--) {
      if (this.records[index].description.match('^Fuel') != null) {
        fuelRecord = this.records[index];
        break;
      }
    }

    if (fuelRecord !== null && fuelRecord.rateCode === 'PCT') {
      fuelRecord.amount = (fuelRecord.rate / 100) * this.getFreightTotal();
      fuelRecord.quanity = this.getFreightTotal();
      fuelRecord.amountInCAD = this.getAmountCAD(fuelRecord.amount);
      fuelRecord.amountInUSD = this.getAmountUSD(fuelRecord.amount);
    }
  }

  protected numberWithCommas(number: number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private calculateTotal() {
    this.gridTotal = 0;
    this.gridTotalInUSD = 0;
    this.gridTotalInCAD = 0;
    this.records.forEach((element) => {
      this.gridTotal += Math.round(element.amount * 100) / 100;
    });

    this.gridTotal = Math.round(this.gridTotal * 100) / 100;

    this.gridTotalInUSD = Math.round(this.getAmountUSD(this.gridTotal));
    this.gridTotalInCAD = Math.round(this.getAmountCAD(this.gridTotal));
    this.store.dispatch(new CarrierInvoiceTotalChanged(this.gridTotal, this.gridTotalInUSD));
  }
  private dispatchChargesChangedEvents() {
    this.store.dispatch(new CarrierInvoiceChargesChanged(this.records));
    this.store.dispatch(new CarrierInvoiceServiceTotalChanged(this.getServiceTotal(), this.getServiceTotal()));
    this.store.dispatch(new CarrierInvoiceFreightTotalChanged(this.getFreightTotal(), this.getFreightTotal()));
  }

  private getChargeTypeTotal(type: string): number {
    let total = 0;
    this.records.forEach((element) => {
      if (element.group.trim() === type) {
        total += element.amount;
      }
    });
    return total;
  }

  private getChargeTypeTotalUSD(type: string): number {
    let total = 0;
    this.records.forEach((element) => {
      if (element.group.trim() === type) {
        total += element.amountInUSD;
      }
    });
    return total;
  }
  private getChargeTypeTotalCAD(type: string): number {
    let total = 0;
    this.records.forEach((element) => {
      if (element.group.trim() === type) {
        total += element.amountInCAD;
      }
    });
    return total;
  }
  private getChargeTypeCharges(type: string): CarrierInvoiceCharge[] {
    return this.records.filter(function (element) {
      return element.group.trim() === type;
    });
  }
  private getFreightTotal(): number {
    return this.getChargeTypeTotal('Linehaul');
  }
  private getServiceTotal(): number {
    return this.getChargeTypeTotal('Accessorial');
  }

  // private getFreightTotal(currencyCode: string): number {
  //   if (currencyCode === 'USD') {
  //     return this.getChargeTypeTotalUSD('Linehaul');
  //   } else {
  //     this.getChargeTypeTotalCAD('Linehaul');
  //   }
  // }
  // private getServiceTotal(currencyCode: string): number {
  //   if (currencyCode === 'USD') {
  //     return this.getChargeTypeTotalUSD('Accessorial');
  //   } else {
  //     this.getChargeTypeTotalCAD('Accessorial');
  //   }
  // }

  private getAmountCAD(amount: number): number {
    if (this.isInternational && this.currentEditCurrency !== 'CAD') {
      return ConversionHelpers.getCanadianAmount(amount, this.conversionRate);
    } else {
      return amount;
    }
  }
  private getAmountUSD(amount: number): number {
    if (this.isInternational && this.currentEditCurrency !== 'USD') {
      return ConversionHelpers.getUSAmount(amount, this.conversionRate);
    } else {
      return amount;
    }
  }

  private createForm() {
    this.newChargeFormGroup = this.formBuilder.group({
      invoiceChargeID: [this.invoiceChargeID],
      description: ['Airport Delivery', [Validators.required]],
      freightClass: [0, [Validators.required]],
      fakFreightClass: [0, [Validators.required]],
      rate: [0.0, [Validators.required]],
      rateQualifier: ['Flat Rate (FR)', [Validators.required]],
      rateCode: ['FR', [Validators.required]],
      quantity: [0.0, [Validators.required]],
      type: ['Accessorial'],
      amount: [0],
      group: ['Accessorial'],
    });
  }
  private createFormGroup = (dataItem) =>
    new FormGroup({
      invoiceChargeID: new FormControl(dataItem.invoiceChargeID),
      ediCode: new FormControl(dataItem.ediCode),
      weight: new FormControl(dataItem.weight),
      dimWeight: new FormControl(dataItem.dimWeight),
      type: new FormControl(dataItem.type),
      group: new FormControl(dataItem.group),
      description: new FormControl(dataItem.description, Validators.required),
      freightClass: new FormControl(dataItem.freightClass, Validators.required),
      fakFreightClass: new FormControl(dataItem.fakFreightClass, Validators.required),
      rate: new FormControl(dataItem.rate === 0 ? dataItem.amount : dataItem.rate, Validators.required),
      rateQualifier: new FormControl(dataItem.rateQualifier, Validators.required),
      rateCode: new FormControl(dataItem.rateCode, Validators.required),
      quantity: new FormControl(dataItem.quantity, Validators.required),
    });
}
