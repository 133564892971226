import { Enterprise } from '@/services/Enterprise';
import { Component, Input, OnInit } from '@angular/core';
import { ShipmentInvoiceAuditUI } from '../../pages/shipment-invoice-audit/ShipmentInvoiceAudit.ui';
import ui = ShipmentInvoiceAuditUI;

@Component({
  selector: 'app-charge-table',
  templateUrl: './charge-table.component.html',
  styleUrls: ['./charge-table.component.scss']
})
export class ChargeTableComponent implements OnInit {
  @Input()
  lineHaulCharges: ui.Charge[];

  @Input()
  accessorialCharges: ui.Charge[];

  @Input() currencyCode: string;

  @Input()
  totalVariance: number;

  @Input()
  subtotalVariance: number;

  @Input()
  grandTotal: number;

  @Input()
  subTotal: number;

  @Input()
  displayFakClass: boolean = false;

  @Input() conversion: number;

  @Input() enterprise: Enterprise;

  @Input() enterpriseCountryCode: string;

  constructor() { }

  ngOnInit() {
    if (this.accessorialCharges === undefined || this.lineHaulCharges === undefined) {
      return;
    }
  }

}
