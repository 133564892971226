import { SpinnerComponent } from "@/bg-common/spinner/spinner.component";
import { CarrierInvoiceRaw, CarrierInvoiceSummary } from "@/services/Invoice";
import { InvoiceService } from "@/services/invoice.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { forkJoin } from "rxjs";
import { finalize, map } from "rxjs/operators";

@Component({
    selector: 'app-raw-invoice-search',
    templateUrl: './raw-invoice-search.component.html',
    styleUrls: ['./raw-invoice-search.component.scss']
})
export class RawInvoiceSearchComponent implements OnInit {

    @ViewChild(SpinnerComponent, { static: true })
    spinner: SpinnerComponent;

    userHasSearched = false;
    invoiceNumber: string = '';
    rawInvoices: CarrierInvoiceRaw[] = [];
    blueShipInvoices: CarrierInvoiceSummary[] = [];

    constructor(private invoiceService: InvoiceService) { }

    onSubmit() {
        this.userHasSearched = true;
        this.blueShipInvoices = [];
        this.rawInvoices = [];

        this.getRawCarrierInvoices();
    }

    getRawCarrierInvoices() {
        this.spinner.loading = true;
        const invoices$ = this.invoiceService.getRawCarrierInvoices(this.invoiceNumber);
        const invoiceSummaries$ = this.invoiceService.getCarrierInvoiceSummary(this.invoiceNumber);

        forkJoin([invoices$, invoiceSummaries$])
            .pipe(
                map(([invoices, invoiceSummaries]) => {
                    this.rawInvoices = invoices.map(invoice => {
                        return {
                            ...invoice,
                            parsedValue: JSON.parse(invoice.value)
                        };
                    });
                    this.blueShipInvoices = invoiceSummaries;
                }),
                finalize(() => {
                    this.spinner.loading = false;
                })
            ).subscribe(
                () => { },
                (error) => {
                    console.error(error);
                    this.spinner.loading = false;
                }
            );
    }

    copyToClipboard(jsonData: any) {
        const jsonString = JSON.stringify(jsonData, null, 2);
        const textarea = document.createElement('textarea');
        textarea.value = jsonString;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('Copied to clipboard');
    }

    ngOnInit(): void {
        console.log("RawInvoiceSearchComponent initialized");
    }
}