import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthCallbackComponent } from './auth/auth-callback/auth-callback.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { CollectionComponent } from './pages/collection/collection.component';
import { CollectionDetailsComponent } from './pages/collection/collection-details.component';
import { AuditQueueComponent } from './pages/audit/audit-queue.component';

import { InvoicesUnmatchedComponent } from './pages/invoices/invoices-unmatched.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { ShipmentHistoryComponent } from '@/bg-common/shipment-history/shipment-history.component';
import { CustomerProfileComponent } from './pages/customer-profile/customer-profile.component';
import { CustomerDetailsComponent } from './pages/customer-profile/customer-details.component';
import { ProactiveHoldComponent } from './pages/proactive/proactive-hold.component';
import { ManualProactiveComponent } from './pages/proactive/manual-proactive.component';
import { ShipmentSearchComponent } from '@/pages/shipment-search/shipment-search.component';
import { TruckloadAuditComponent } from './pages/tl-audit/truckload-audit.component';
import { TruckloadEditComponent } from './pages/tl-audit-edit/truckload-edit.component';
import { TruckloadAuditComponentOld } from './pages/tl-audit-old/truckload-audit-old.component';
import { DuplicateCarrierComponent } from './pages/duplicate-carrier/duplicate-carrier.component';
import { InvoicesSummaryComponent } from './pages/invoices-summary/invoices-summary.component';
import { InvoiceReportsComponent } from './pages/invoice-reports/invoice-reports.component';
import { UnmatchedEditComponent } from './components/unmatched-edit/unmatched-edit.component';
import { InvoiceManagementComponent } from './components/invoice-management/invoice-management.component';
import { UntrackedInvoicesComponent } from './components/untracked-invoices/untracked-invoices.component';
import { AuditInvoiceComponent } from './components/audit-invoice/audit-invoice.component';
import { ApproveToPayComponent } from './pages/approve-to-pay/approve-to-pay.component';
import { ApproveToPayDetailComponent } from './pages/approve-to-pay-detail/approve-to-pay-detail.component';
import { InvoiceImportComponent } from './pages/invoice-import/invoice-import.component';
import { RawInvoiceSearchComponent } from './pages/raw-invoice-search/raw-invoice-search.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService],
  },
  {
    path: 'bad-debt',
    component: CollectionComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoices',
    component: InvoiceManagementComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'unmatched',
        component: InvoicesUnmatchedComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'unmatched/:id',
        component: UnmatchedEditComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'untracked',
        component: UntrackedInvoicesComponent,
        canActivate: [AuthGuardService]
      }
    ]
  },
  {
    path: 'test/:shipmentId',
    component: AuditInvoiceComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'paramsChange'
  },
  {
    path: 'shipment-history',
    component: ShipmentHistoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'bad-debt-details/:id',
    component: CollectionDetailsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'paramsChange',
  },
  {
    path: 'audit-queue',
    component: AuditQueueComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'paramsChange',
  },
  {
    path: 'audit-queue/:queuetype',
    component: AuditQueueComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'paramsChange',
  },
  {
    path: 'customer-profile',
    component: CustomerProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'approve-to-pay',
    component: ApproveToPayComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'approve-to-pay/new',
    component: ApproveToPayDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'approve-to-pay/:id',
    component: ApproveToPayDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoice-import',
    component: InvoiceImportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'proactive/manual',
    component: ManualProactiveComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'proactive/hold',
    component: ProactiveHoldComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'tl-audit-incident',
    component: TruckloadAuditComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'truckload-audit',
    component: TruckloadAuditComponentOld,
    canActivate: [AuthGuardService],
  },
  {
    path: 'truckload-edit/:shipmentid/:invoiceid',
    component: TruckloadEditComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'customer-profile-details/:id',
    component: CustomerDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'shipment-search',
    component: ShipmentSearchComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoice-aging',
    component: InvoiceReportsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'raw-invoice-search',
    component: RawInvoiceSearchComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'duplicate-invoices',
    component: DuplicateCarrierComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'invoices-summary',
    component: InvoicesSummaryComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }
