export interface IAgedCarrierInvoices {
  primaryReference: string;
  pro: string;
  friendlyName: string;
  carrierInvoiceID: number;
  proCount: number;
  customerAccountNumber: string;
  invoiceDate: Date;
  invoiceNumber: string;
  isMatrixIQ: boolean;
  chargeModel: string;
  paymentMethod: string;
  scac: string;
  freightTotal: number;
  serviceTotal: number;
  invoiceTotal: number;
  paymentTerms: string;
  weight: number;
  currencyCode: string;
  distance: number;
  isAllocated: boolean;
  normalizedTotal: number;
  normalizedCurrencyCode: string;
  mode: string;
  carrierName: string;
  mcNumber: string;
  dotNumber: string;
  groupOwner: string;
  isError: boolean;
  processNote: string;
  invoiceID: number;
  batchIdentifier: string;
  weightUOM: string;
  volumeUOM: string;
  volume: number;
  quantity: number;
  pickupDate: Date;
  deliveryDate: Date;
  importSource: string;
  extractedValue: string;
  dateCreated: Date;
  agedDays: number;
}
export interface IExportDateInvoiceReport {
  PrimaryReference: string;
  PRO: string;
  AgedDate: number;
  Status: string;
  ExtractNumber: string;
}
export interface IFormDateCtrl {
  startDate: string,
  endDate: string
}

export interface IAgedCarrierInvoiceStatusCount {
  refCarrierInvoiceStatusID: number;
  status: string;
  count: number;
}

export enum FormInvoiceReportsCtrls {
  startDate = 'startDate',
  endDate = 'endDate'
}

export const HEADER_CLASS = {
  style: { 'text-align': 'left' }
}

export function ConvertJSONToCSVHelper(uninvoicedData: any[]): string {
  const json = uninvoicedData;

  const replacer = function (key, value) {
    return value === null ? '' : value;
  };

  const header = Object.keys(json[0]);
  const csv = json.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  const finalcsv = csv.join('\r\n');
  return finalcsv;
}
