import { PendingAddressResponse } from '@/services/Invoice';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent implements OnInit {

  @Input() data: any;
  @Input() hideName: boolean = false;
  @Input() actualDateLabel?: string;
  constructor() { }

  ngOnInit() {
  }

  url() {
    if (!this.data) {
      return "javascript:void(0)";
    }

    const fullAddress = `${this.data.addressLine1} ${this.data.city} ${this.data.stateProvince} ${this.data.postalCode}`;
    return 'https://www.google.com/search?q=' + encodeURIComponent(fullAddress);
  }  

}
