import { AppState } from '@/appstate.model';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoadSettlementReasonsOld, SaveBBillOld } from '../../actions/tl-audit-bbill-old.actions';
import { TLBBillOld } from '../../dto/TruckloadAuditOLDDTO';
import { BBillOld } from '../../models/TruckloadAuditOld.ui';

@Component({
  selector: 'app-tl-bbill-old',
  templateUrl: './tl-bbill-old.component.html',
  styleUrls: ['./tl-bbill-old.component.scss'],
})
export class TlBbillComponentOld implements OnInit {
  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) { }

  savingBBill = false;
  actionBtnDisabled = false;
  tlAuditSettlementReasons: string[];
  createBBill: FormGroup = null;
  bbill: BBillOld;
  ngOnInit() {
    this.setListeners();
    this.store.dispatch(new LoadSettlementReasonsOld());
  }

  protected saveBBill() {
    this.savingBBill = true;
    this.bbill.previousPayment = this.createBBill.get('bBillPreviousPayment').value;
    this.bbill.accountNumber = this.createBBill.get('bBillAccountNumber').value;
    this.bbill.reasons = this.createBBill.get('bBillReason').value;

    const truckloadBBill = new TLBBillOld(this.bbill);
    this.store.dispatch(new SaveBBillOld(this.bbill.invoiceID, truckloadBBill));
  }

  private createForm() {
    this.createBBill = this.formBuilder.group({
      bBillAccountNumber: [this.bbill.accountNumber, Validators.required],
      bBillPreviousPayment: [0, [Validators.min(0)]],
      bBillReason: [null, Validators.required],
    });
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditBBillOldState.saveInProgress)
      .subscribe((x) => {
        if (x) {
          this.savingBBill = x;
          this.showSpinner(true, 'Saving....');
        }
      });
    this.store
      .select((x) => x.TLAuditBBillOldState.savedSuccess)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.alertMessage.showAlertMessage('B-Bill settings saved successfully', 'Info');
        }
      });
    this.store
      .select((x) => x.TLAuditBBillOldState.savedError)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.alertMessage.showAlertMessage('Error saving B-Bill settings: ' + x, 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditBBillOldState.bbill)
      .subscribe((x) => {
        if (x) {
          this.bbill = x;
          this.createForm();
        }
      });
    this.store
      .select((x) => x.TLAuditBBillOldState.tlSettlementReasons)
      .subscribe((x) => {
        if (x) {
          this.tlAuditSettlementReasons = x;
        }
      });
    this.store
      .select((x) => x.TLAuditBBillOldState.loadingSettlementReasonError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Error laoding settlement reasons: ' + x, 'Error');
        }
      });
  }

  private showSpinner(show: boolean, text: string = '') {
    this.appSpinner.loading = show;
    this.appSpinner.text = text;
  }
}
