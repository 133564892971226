import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { StartupService } from '../startup.service';
import { MarkupCharge, MarkupRuleResponse, QuoteMarkupRequest, QuoteRequest, QuoteResponse, UpliftRequest, BGRMarkupThirdRateDTO } from '../models/BgRating';
import * as bgr from '@/models/BgRating';

import { AuthService } from '../auth/auth.service';
import { DatePipe } from '@angular/common';
import { catchError } from 'rxjs/internal/operators/catchError';
import { BgRatingHelper } from '@/components/bgRatingHelper';
import * as v3_models from '@/models/v3/Shipment';
import { ShipmentInvoiceAuditUI } from '@/pages/shipment-invoice-audit/ShipmentInvoiceAudit.ui';
import ui = ShipmentInvoiceAuditUI;
import { EDIChargeMap } from '@/models/EDIChargeMap';

@Injectable()
export class BgRatingService {
  constructor(
    private httpClient: HttpClient,
    private startupService: StartupService,
    private authService: AuthService,
    private datePipe: DatePipe) { }

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      UserName: this.authService.BlueShipUser.userName,
      Referer: 'Gryphon',
    }),
  };
  public contractName: string = '';

  public getQuote(request: QuoteRequest): Observable<QuoteResponse> {
    const url = `${this.startupService.bgRatingApiUrl}quote`;
    return this.httpClient.post<QuoteResponse>(url, request, { headers: this.httpOptions.headers }).pipe((response) => response);
  }

  public quote(shipment: v3_models.Shipment, carriers: string[] = []): Observable<QuoteResponse> {
    let request = this.shipmentToRequest(shipment);

    request.carriers = carriers;

    return this.getQuote(request);
  }

  public markup(shipment: v3_models.Shipment, invoiceOrCharges: ui.CarrierInvoice | ui.Charge[] | bgr.Charge[] = null): Observable<QuoteResponse> {

    let request = this.shipmentToMarkupRequest(shipment);

    if (invoiceOrCharges instanceof ui.CarrierInvoice) {
      let invoice = <ui.CarrierInvoice>invoiceOrCharges;
      let invoiceCharges = invoice.lineHaulCharges.concat(invoice.accessorialCharges);
      request.ratesToMarkup[0].charges = this.chargeToMarkupCharge(invoiceCharges);
    } else {
      request.ratesToMarkup[0].charges = this.chargeToMarkupCharge(<bgr.Charge[]>invoiceOrCharges);
    }

    return this.getMarkupCharges(request);
  }

  public uplift(
    shipment: v3_models.Shipment,
    invoiceOrCharges: bgr.Charge[] | ui.CarrierInvoice | ui.Charge[],
    uplift: v3_models.Markup): Observable<QuoteResponse> {

    let request = this.shipmentToMarkupRequest(shipment) as UpliftRequest;

    if (invoiceOrCharges instanceof ui.CarrierInvoice) {
      let invoice = <ui.CarrierInvoice>invoiceOrCharges;
      let invoiceCharges = invoice.lineHaulCharges.concat(invoice.accessorialCharges);
      request.ratesToMarkup[0].charges = this.chargeToMarkupCharge(invoiceCharges);
    }
    else {
      request.ratesToMarkup[0].charges = this.chargeToMarkupCharge(<bgr.Charge[]>invoiceOrCharges);
    }

    request.upliftAmount = uplift.amount;
    request.upliftType = uplift.type;

    return this.getRateUplift(request);
  }

  private shipmentToRequest(shipment: v3_models.Shipment): QuoteRequest {
    return {
      accountNumber: shipment.enterpriseAccountNumber,
      originPostal: shipment.origin.postalCode,
      originStateProvince: shipment.origin.stateProvince,
      originCity: shipment.origin.city,
      originCountry: shipment.origin.countryCode,
      destinationCountry: shipment.destination.countryCode,
      destinationPostal: shipment.destination.postalCode,
      destinationStateProvince: shipment.destination.stateProvince,
      destinationCity: shipment.destination.city,
      shipmentDate: shipment.origin.actualDate || shipment.origin.earliestDate,
      carriers: [],
      services: shipment.services.filter(x => !x.isDeleted).map(x => x.code),
      items: shipment.items
        .filter(x => x.weight.value > 0.0 && !x.isDeleted) // Remove 0 weight items for BG rating
        .map(x => {
          return {
            freightClass: (x.freightClass as number).toString(),
            weight: x.weight.value,
            weightUom: x.weight.uom,
            length: x.dimension.length,
            width: x.dimension.width,
            height: x.dimension.height,
            quantity: x.quantity.value,
            itemUnitType: x.quantity.uom
          };
        }),
      options: {
        skipMarkup: false,
        skipSave: false,
        skipDistance: false,
        skipFilter: false,
        skipDynamicLtlRating: false,
        skipLtlRating: false,
        skipVolumeRating: false,
        includeProposal: false,
      }
    } as QuoteRequest;
  }

  private shipmentToMarkupRequest(shipment: v3_models.Shipment): QuoteMarkupRequest {
    let quote = shipment.quotes.find(x => x.isSelected && x.type.toLowerCase() == 'charge');
    this.contractName = quote.contractName;

    return {
      accountNumber: shipment.enterpriseAccountNumber,// uiShipment.accountNumber,
      originPostal: shipment.origin.postalCode,// uiShipment.origin.postalCode,
      originStateProvince: shipment.origin.stateProvince, // uiShipment.origin.stateProvince,
      originCountry: shipment.origin.countryCode, //uiShipment.origin.countryCode,
      destinationCountry: shipment.destination.countryCode,// uiShipment.destination.countryCode,
      destinationPostal: shipment.destination.postalCode, //uiShipment.destination.postalCode,
      destinationStateProvince: shipment.destination.stateProvince, //uiShipment.destination.stateProvince,
      shipmentDate: shipment.origin.earliestDate,//shipment.addresses[0].earliestDate,
      items: shipment.items
        .filter(x => x.weight.value > 0.0) // Remove 0 weight items for BG rating
        .map(x => {
          return {
            freightClass: (x.freightClass as number).toString(),
            weight: x.weight.value,
            length: x.dimension.length,
            width: x.dimension.width,
            height: x.dimension.height,
            quantity: x.quantity.value,
            itemUnitType: x.quantity.uom
          };
        }),//this.mapItems(auditItemListComponent),
      ratesToMarkup: [{
        description: 'Standard LTL',
        scac: quote.scac,
        mode: quote.mode,
        charges: this.chargeToMarkupCharge(quote.charges),
        currencyCode: quote.currencyCode || 'USD'
      }]
    } as QuoteMarkupRequest;
  }

  private chargeToMarkupCharge(charges: (bgr.Charge | ui.Charge)[]): MarkupCharge[] {
    let helper = new BgRatingHelper();
    let tmp = <any[]>charges;

    return tmp.map(x => <MarkupCharge>{
      amount: x.amount || x.total,
      description: x.description,
      type: helper.getBGChargeType(x.type),
      subType: helper.getBGChargeSubType(x.type),
      code: !x.code ? x.ediCode : x.code,
      freightClass: x.freightClass ? x.freightClass : 0,
    });
  }

  public getMarkupCharges(request: QuoteMarkupRequest): Observable<QuoteResponse> {
    const url = `${this.startupService.bgRatingApiUrl}quote/markup`;
    return this.httpClient.post<QuoteResponse>(url, request, { headers: this.httpOptions.headers }).pipe((response) => response);
  }

  public getMarkupRules(currentDate: Date, accountNumber: string): Observable<MarkupRuleResponse> {
    const url = `${this.startupService.bgRatingApiUrl}PricingConfiguration/markuprule?accountNumber=` + accountNumber + `&effectiveDate=` + this.datePipe.transform(currentDate, 'y-MM-d');
    return this.httpClient
      .get<MarkupRuleResponse>(url,
        { headers: this.httpOptions.headers })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        })
      );
  }

  getRateUplift(upliftRequest: UpliftRequest): Observable<any> {
    const url = `${this.startupService.bgRatingApiUrl}quote/uplift`;
    return this.httpClient.post<any>(url, upliftRequest, { headers: this.httpOptions.headers }).pipe((response) => response);
  }

  public getThirdRateMarkup(
    bgrMarkupThirdRateDTO: BGRMarkupThirdRateDTO
  ): Observable<any> {
    const url = `${this.startupService.bgRatingApiUrl}quote/markup`
    return this.httpClient.post<BGRMarkupThirdRateDTO>(url, bgrMarkupThirdRateDTO, { headers: this.httpOptions.headers }).pipe((response) => response);
  }
}
