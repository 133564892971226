export class PendingCarrierInvoice {
  id: number;
  invoiceID: number;
  shipmentID: number;
  batchIdentifier: string;
  note: string;
  pro: string;
  proCount: number;
  primaryReference: string;
  customerAccountNumber: string;
  customerName: string
  itemTotal: number;
  invoiceDate: Date;
  dateCreated: Date;
  aged?: number;
  invoiceNumber: string;
  pendingCarrierInvoiceStatus: PendingCarrierInvoiceStatus;
  isMatrixIQ: boolean;
  scac: string;
  freightTotal: number;
  serviceTotal: number;
  invoiceTotal: number;
  paymentTerms: string;
  weight: number;
  currencyCode: string;
  distance: number;
  normalizedTotal: number;
  normalizedCurrencyCode: number;
  mode: string;
  carrierName: string;
  pickupDate: Date;
  mcNumber: string;
  dotNumber: string;
  groupOwner: string;
  isError: boolean;
  multipleShipments: number;
  origin: PendingAddressResponse;
  destination: PendingAddressResponse;
  billTo: PendingAddressResponse;
  references: PendingReference[];
  items?: CarrierInvoiceItem[];
  isReadyToConvert: boolean;
  flagStatus: string;
  noteCount: number;
  enterpriseId: number;
  salesRep: string;
  primaryContact: string;
  department: string;
  departmentGroup: string;
}

export class MatchPendingInvoiceManualBOL {
  id: number;
  primaryReference: string;
}

export class PendingCharge {
  id: number;
  pendingCarrierInvoiceID: number;
  type: string;
  sequenceNumber: number;
  description: string;
  ediCode: string;
  amount: number;
  rateQualifier: string;
  rate: string;
  quantity: number;
  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreightClass: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;

  charges: CreatePayment[];
  errors: CreatePayment[];
}

export class PendingReference {
  id: number;
  pendingCarrierInvoiceID: number;
  isPrimary: boolean;
  type: string;
  value: string;
}

export class CreatePayment {
  collectionID: number;
  authorizationCode: string;
  paymentAmount: number;
  paymentDate: Date;
  agencyInvoiceNumber: string;
  agencyInvoiceAmount: number;
  agencyID: number;
  escalatedAgencyID: number;
  collectorID: number;
}

export class InvoiceError {
  id: number;
  pendingCarrierInvoiceID: number;
  type: string;
  message: string;
}

export enum PendingCarrierInvoiceStatus {
  PendingCarrierInvoiceReceived,
  CarrierInvoiceGenerated,
  CarrierInvoiceShipmentNotFound,
  CarrierInvoiceRulePassed,
  CarrierInvoiceRuleFailed,
  CustomerInvoiceGenerated,
  CustomerInvoiceRulePassed,
  CustomerInvoiceRuleFailed,
  InvoiceRemsPickedup,
  InvoiceRemsSendSuccessful,
  InvoiceRemsSendFailed,
  InvoiceBillNumberReceived,
  InvoiceBillNumberFailed,
  CarrierInvoiceDuplicateInvoiceID,
  ShipmentNotFoundEmailSent,
  REMSInvoiceDataGathered,
  REMSInvoiceGrouped,
  InvoiceBlockedFromREMS
}
export class MoveInvoiceToShipment {
  carrierInvoiceID: number;
  primaryReference: string;
  closingNote: string;
}

export class UpdateAuditorResponse {
  validationResult: ValidationResult;
}
export class ValidationResult {
  isValid: boolean;
  errors: ValidationFailure[];
}
export class ValidationFailure {
  PropertyName: string;
  ErrorMessage: string;
}

export class InvoiceAudit {
  totalVariance: number;
  subtotalVariance: number;
  errorList: string[];
}

export class AssignAuditorOnCarrierInvoice {
  id: number;
  auditor: string;
  confirmedOverwrite: boolean;
}

export class MarkCarrierInvoiceComplete {
  id: number;
  shipmentID: number;
}

// error handling
export class ErrorResult {
  isValid: boolean;
  errors: Error[];
}
export class Error {
  propertyName: string;
  errorMessage: string;
}

export enum UpdatePendingInvoiceAction {
  Update = 0,
  Reset = 1,
  SetShipment = 2,
  SelectedBOL = 3
}

export class SavePendingError {
  type: string;
  message: string;
}

export class SavePendingInvoice {
  invoiceID: number;
  status: string;
  shipmentID: number;
  note: string;
  selectedPrimaryReference: string;
  errors: SavePendingError[];
  updatePendingInvoiceAction: UpdatePendingInvoiceAction;
}

export class ReopenQueue {
  shipmentID: number;
  queueType: string;
  invoiceNumber: string;
}

export class UpdateCarrierInvoice {
  shipmentID: number;
  invoiceNumber: string;
  isSecondary: boolean;
  status: string;
  auditor: string;
  confirmedOverwrite: boolean;
  customerInvoiceID: number;
  closingNote: string;
  primaryReference: string;
  bbillAccountNumber: string;
  bbillPreviousPayment: number;
  bbillSettlementReasons: string[];
  updateCarrierAction: UpdateCarrierInvoiceAction;
}

export enum UpdateCarrierInvoiceAction {
  Update = 0,
  Move = 1,
  MarkComplete = 2,
  Approve = 3,
  Auditor = 4,
  BBill = 5
}

export class PendingAddressResponse {
  name: string;
  address: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

export class CarrierInvoiceItem {
  id: number;
  carrierInvoiceId: number;
  description: string;
  freightClass: number;
  nmfc: string;
  dimension: CarrierInvoiceItemDimension;
  quantity: CarrierInvoiceItemQuantity;
  weight: CarrierInvoiceItemWeight;
}

export class CarrierInvoiceItemDimension {
  uom: string;
  length: number;
  width: number;
  height: number;
}

export class CarrierInvoiceItemWeight {
  uom: string;
  value: number;
}

export class CarrierInvoiceItemQuantity {
  uom: string;
  value: number;
  numberOfPieces: number;
  piecesUom: string;
}
